.elementor-1092
  .elementor-element.elementor-element-0c62363:not(.elementor-motion-effects-element-type-background),
.elementor-1092
  .elementor-element.elementor-element-0c62363
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../assets/images/20190525_094136-scaled.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.elementor-1092
  .elementor-element.elementor-element-0c62363
  > .elementor-background-overlay {
  background-color: rgba(0, 0, 0, 0.55);
  opacity: 0.8;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1092 .elementor-element.elementor-element-0c62363 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-1092
  .elementor-element.elementor-element-b5e3032
  .elementor-spacer-inner {
  height: 227px;
}
.elementor-1092 .elementor-element.elementor-element-9767a61 {
  text-align: left;
}
.elementor-1092
  .elementor-element.elementor-element-9767a61
  .elementor-heading-title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}
.elementor-1092
  .elementor-element.elementor-element-9767a61
  > .elementor-widget-container {
  margin: 0 0 -21px;
}
.elementor-1092 .elementor-element.elementor-element-3eb3983 {
  --divider-border-style: solid;
  --divider-border-color: #f2b22e;
  --divider-border-width: 3.6px;
}
.elementor-1092
  .elementor-element.elementor-element-3eb3983
  .elementor-divider-separator {
  width: 152px;
  margin: 0 auto;
  margin-left: 0;
}
.elementor-1092
  .elementor-element.elementor-element-3eb3983
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.elementor-1092
  .elementor-element.elementor-element-3eb3983
  > .elementor-widget-container {
  padding: 0 0 -5px;
}
.elementor-1092 .elementor-element.elementor-element-a324d7c {
  text-align: left;
}
.elementor-1092
  .elementor-element.elementor-element-a324d7c
  .elementor-heading-title {
  color: #fff;
  font-size: 51px;
}
.elementor-1092
  .elementor-element.elementor-element-a324d7c
  > .elementor-widget-container {
  padding: 0 0 50px;
}
.elementor-1092
  .elementor-element.elementor-element-4281555
  .elementor-spacer-inner {
  height: 74px;
}
.elementor-1092
  .elementor-element.elementor-element-62fed6c
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-1092
  .elementor-element.elementor-element-ccb58dc
  .eael-grid-post-holder {
  background-color: #2c2d31;
  border-radius: 3px;
  box-shadow: 2px 3px 6px 0px rgba(0, 0, 0, 0.5);
}
.elementor-1092
  .elementor-element.elementor-element-ccb58dc
  .eael-entry-title
  a {
  color: #fff;
}
.elementor-1092
  .elementor-element.elementor-element-ccb58dc
  .eael-entry-title:hover,
.elementor-1092
  .elementor-element.elementor-element-ccb58dc
  .eael-entry-title
  a:hover {
  color: #fed52c;
}
.elementor-1092 .elementor-element.elementor-element-ccb58dc .eael-entry-title {
  text-align: center;
}
.elementor-1092
  .elementor-element.elementor-element-ccb58dc
  .eael-grid-post-excerpt
  p {
  color: #fff;
  text-align: justify;
}
.elementor-1092 .elementor-element.elementor-element-ccb58dc .eael-entry-meta,
.eael-entry-meta a {
  color: #fff;
}
.elementor-1092
  .elementor-element.elementor-element-ccb58dc
  .eael-entry-footer {
  justify-content: flex-start;
}
.elementor-1092 .elementor-element.elementor-element-ccb58dc .eael-entry-meta {
  justify-content: flex-start;
}
.elementor-1092
  .elementor-element.elementor-element-ccb58dc
  .eael-grid-post
  .eael-entry-overlay {
  background-color: rgba(0, 0, 0, 0.75);
}
.elementor-1092
  .elementor-element.elementor-element-ccb58dc
  .eael-grid-post
  .eael-entry-overlay
  > i {
  color: #fff;
  font-size: 33px;
}
.elementor-1092
  .elementor-element.elementor-element-ccb58dc
  .eael-grid-post
  .eael-entry-overlay
  > img {
  height: 33px;
  width: 33px;
}
.elementor-1092
  .elementor-element.elementor-element-ccb58dc
  .eael-post-elements-readmore-btn {
  color: #fed52c;
}
.elementor-1092
  .elementor-element.elementor-element-ccb58dc
  > .elementor-widget-container {
  margin: 0;
  padding: 0;
  border-radius: 0;
}
.elementor-1092
  .elementor-element.elementor-element-7517cef
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-1092
  .elementor-element.elementor-element-87f6c88:not(.elementor-motion-effects-element-type-background),
.elementor-1092
  .elementor-element.elementor-element-87f6c88
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../assets/images/template-for-banner-scaled-1.jpg");
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.elementor-1092 .elementor-element.elementor-element-87f6c88 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-1092
  .elementor-element.elementor-element-87f6c88
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1092
  .elementor-element.elementor-element-5e617b6
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-1092 .elementor-element.elementor-element-658528a {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-1092
  .elementor-element.elementor-element-658528a
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
  margin-left: 0;
}
.elementor-1092
  .elementor-element.elementor-element-658528a
  .elementor-divider {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-1092
  .elementor-element.elementor-element-213aac3
  .elementor-heading-title {
  color: #fff;
}
.elementor-1092
  .elementor-element.elementor-element-213aac3
  > .elementor-widget-container {
  margin: 0 0 18px;
}
.elementor-1092
  .elementor-element.elementor-element-d4a3992
  > .elementor-widget-container {
  margin: 0 0 48px;
}
.elementor-1092 .elementor-element.elementor-element-e805da3 .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  fill: #000;
  color: #000;
  background-color: #eab22e;
  border-radius: 50px;
  padding: 25px 60px;
}
.elementor-1092
  .elementor-element.elementor-element-7d89508
  .elementor-spacer-inner {
  height: 100px;
}
@media (min-width: 1025px) {
  .elementor-1092
    .elementor-element.elementor-element-0c62363:not(.elementor-motion-effects-element-type-background),
  .elementor-1092
    .elementor-element.elementor-element-0c62363
    > .elementor-motion-effects-container
    > .elementor-motion-effects-layer {
    background-attachment: scroll;
  }
}
@media (max-width: 1024px) {
  .elementor-1092
    .elementor-element.elementor-element-b5e3032
    .elementor-spacer-inner {
    height: 100px;
  }
  .elementor-1092
    .elementor-element.elementor-element-9767a61
    .elementor-heading-title {
    font-size: 19px;
  }
  .elementor-1092
    .elementor-element.elementor-element-a324d7c
    .elementor-heading-title {
    font-size: 22px;
  }
  .elementor-1092
    .elementor-element.elementor-element-a324d7c
    > .elementor-widget-container {
    padding: 0;
  }
  .elementor-1092
    .elementor-element.elementor-element-4281555
    .elementor-spacer-inner {
    height: 38px;
  }
  .elementor-1092
    .elementor-element.elementor-element-658528a
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
}
@media (max-width: 767px) {
  .elementor-1092
    .elementor-element.elementor-element-b5e3032
    .elementor-spacer-inner {
    height: 75px;
  }
  .elementor-1092 .elementor-element.elementor-element-9767a61 {
    text-align: left;
  }
  .elementor-1092
    .elementor-element.elementor-element-9767a61
    .elementor-heading-title {
    font-size: 18px;
  }
  .elementor-1092
    .elementor-element.elementor-element-3eb3983
    .elementor-divider {
    text-align: left;
  }
  .elementor-1092
    .elementor-element.elementor-element-3eb3983
    .elementor-divider-separator {
    margin: 0 auto;
    margin-left: 0;
  }
  .elementor-1092
    .elementor-element.elementor-element-3eb3983
    > .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .elementor-1092 .elementor-element.elementor-element-a324d7c {
    text-align: left;
  }
  .elementor-1092
    .elementor-element.elementor-element-a324d7c
    .elementor-heading-title {
    font-size: 19px;
  }
  .elementor-1092
    .elementor-element.elementor-element-a324d7c
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 11px;
  }
  .elementor-1092
    .elementor-element.elementor-element-4281555
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-1092
    .elementor-element.elementor-element-62fed6c
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-1092
    .elementor-element.elementor-element-658528a
    > .elementor-widget-container {
    padding: 0;
  }
}

.support-us {
  font-size: 51px;
  font-style: normal;
  font-weight: 700;
  color: rgb(255, 255, 255);
  font-family: "Trebuchet MS", sans-serif;
  text-transform: uppercase;
}
