.elementor-1058
  .elementor-element.elementor-element-0eab6a0:not(.elementor-motion-effects-element-type-background),
.elementor-1058
  .elementor-element.elementor-element-0eab6a0
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../assets/images/IMG_20190525_111933-1024x768-1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.elementor-1058
  .elementor-element.elementor-element-0eab6a0
  > .elementor-background-overlay {
  background-color: rgba(0, 0, 0, 0.78);
  opacity: 0.8;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1058 .elementor-element.elementor-element-0eab6a0 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-1058
  .elementor-element.elementor-element-1d9be91
  .elementor-spacer-inner {
  height: 214px;
}
.elementor-1058 .elementor-element.elementor-element-da4446b {
  text-align: left;
}
.elementor-1058
  .elementor-element.elementor-element-da4446b
  .elementor-heading-title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}
.elementor-1058
  .elementor-element.elementor-element-da4446b
  > .elementor-widget-container {
  margin: 0 0 -20px;
}
.elementor-1058 .elementor-element.elementor-element-03a77d8 {
  --divider-border-style: solid;
  --divider-border-color: #f2b22e;
  --divider-border-width: 3.5px;
}
.elementor-1058
  .elementor-element.elementor-element-03a77d8
  .elementor-divider-separator {
  width: 100px;
  margin: 0 auto;
  margin-left: 0;
}
.elementor-1058
  .elementor-element.elementor-element-03a77d8
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.elementor-1058
  .elementor-element.elementor-element-03a77d8
  > .elementor-widget-container {
  padding: 0;
}
.elementor-1058 .elementor-element.elementor-element-ac575ee {
  text-align: left;
}
.elementor-1058
  .elementor-element.elementor-element-ac575ee
  .elementor-heading-title {
  color: #fff;
  font-size: 44px;
}
.elementor-1058
  .elementor-element.elementor-element-ac575ee
  > .elementor-widget-container {
  padding: 0 0 50px;
}
.elementor-1058
  .elementor-element.elementor-element-c697be1
  .elementor-spacer-inner {
  height: 150px;
}
.elementor-1058
  .elementor-element.elementor-element-cc569a8
  > .elementor-container
  > .elementor-row
  > .elementor-column
  > .elementor-column-wrap
  > .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.elementor-1058
  .elementor-element.elementor-element-cc569a8:not(.elementor-motion-effects-element-type-background),
.elementor-1058
  .elementor-element.elementor-element-cc569a8
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #eaeaea;
}
.elementor-1058
  .elementor-element.elementor-element-cc569a8
  > .elementor-background-overlay {
  opacity: 0.8;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1058
  .elementor-element.elementor-element-cc569a8
  .elementor-background-overlay {
  filter: brightness(100%) contrast(100%) saturate(100%) blur(0px)
    hue-rotate(0deg);
}
.elementor-1058 .elementor-element.elementor-element-cc569a8 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-1058
  .elementor-element.elementor-element-a691743
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-1058 .elementor-element.elementor-element-0d3397b {
  text-align: center;
}
.elementor-1058
  .elementor-element.elementor-element-0d3397b
  .elementor-heading-title {
  color: #000;
}
.elementor-1058
  .elementor-element.elementor-element-0d3397b
  > .elementor-widget-container {
  margin: 0 0 -25px;
  padding: 0;
}
.elementor-1058 .elementor-element.elementor-element-d5d0e8f {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-1058
  .elementor-element.elementor-element-d5d0e8f
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
}
.elementor-1058
  .elementor-element.elementor-element-d5d0e8f
  .elementor-divider {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-1058
  .elementor-element.elementor-element-62c88d7
  > .elementor-container
  > .elementor-row
  > .elementor-column
  > .elementor-column-wrap
  > .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.elementor-1058
  .elementor-element.elementor-element-82c5286
  > .elementor-element-populated {
  margin: 0 50px 0 0;
}
.elementor-1058
  .elementor-element.elementor-element-ce39b65
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-1058 .elementor-element.elementor-element-dd10652 {
  color: #333;
}
.elementor-1058 .elementor-element.elementor-element-b21a9f4 {
  color: #333;
}
.elementor-1058 .elementor-element.elementor-element-173eb4f .elementor-button {
  font-size: 18px;
  font-weight: 700;
  fill: #041793;
  color: #041793;
  background-color: #eab22e;
  padding: 25px 60px;
}
.elementor-1058
  .elementor-element.elementor-element-173eb4f
  .elementor-button:hover,
.elementor-1058
  .elementor-element.elementor-element-173eb4f
  .elementor-button:focus {
  color: #fff;
}
.elementor-1058
  .elementor-element.elementor-element-173eb4f
  .elementor-button:hover
  svg,
.elementor-1058
  .elementor-element.elementor-element-173eb4f
  .elementor-button:focus
  svg {
  fill: #fff;
}
.elementor-1058 .elementor-element.elementor-element-173eb4f {
  width: 100%;
  max-width: 100%;
}
.elementor-1058
  .elementor-element.elementor-element-94501a6
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-1058
  .elementor-element.elementor-element-86d6f60:not(.elementor-motion-effects-element-type-background)
  > .elementor-column-wrap,
.elementor-1058
  .elementor-element.elementor-element-86d6f60
  > .elementor-column-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../assets/images/20190525_120708-scaled.jpg");
  background-position: center center;
  background-size: cover;
}
.elementor-1058
  .elementor-element.elementor-element-86d6f60
  > .elementor-element-populated
  > .elementor-background-overlay {
  opacity: 0.5;
}
.elementor-1058
  .elementor-element.elementor-element-86d6f60
  > .elementor-element-populated,
.elementor-1058
  .elementor-element.elementor-element-86d6f60
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1058
  .elementor-element.elementor-element-86d6f60
  > .elementor-background-slideshow {
  border-radius: 42px;
}
.elementor-1058
  .elementor-element.elementor-element-86d6f60
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0 15px 0 0;
  padding: 0;
}
.elementor-1058
  .elementor-element.elementor-element-86d6f60
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1058
  .elementor-element.elementor-element-ba24835
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-1058
  .elementor-element.elementor-element-96ed475
  .elementor-spacer-inner {
  height: 40px;
}
.elementor-1058
  .elementor-element.elementor-element-ff5421a
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-1058
  .elementor-element.elementor-element-0f1a0eb
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-1058 .elementor-element.elementor-element-9a1f72d {
  text-align: center;
}
.elementor-1058
  .elementor-element.elementor-element-9a1f72d
  .elementor-heading-title {
  color: #000;
}
.elementor-1058
  .elementor-element.elementor-element-9a1f72d
  > .elementor-widget-container {
  margin: 0 0 -26px;
}
.elementor-1058 .elementor-element.elementor-element-06c516a {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-1058
  .elementor-element.elementor-element-06c516a
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
}
.elementor-1058
  .elementor-element.elementor-element-06c516a
  .elementor-divider {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-1058
  .elementor-element.elementor-element-06c516a
  > .elementor-widget-container {
  margin: 0 0 17px;
}
.elementor-1058
  .elementor-element.elementor-element-4384ff4
  .elementor-image
  img {
  border-radius: 35px;
}
.elementor-1058
  .elementor-element.elementor-element-3636875
  > .elementor-element-populated {
  padding: 0 0 0 23px;
}
.elementor-1058 .elementor-element.elementor-element-9374cea {
  color: #7a7a7a;
  font-weight: 600;
}
.elementor-1058 .elementor-element.elementor-element-79cab85 {
  color: #7a7a7a;
  font-weight: 600;
}
.elementor-1058
  .elementor-element.elementor-element-6eba521
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-1058
  .elementor-element.elementor-element-4c106bb:not(.elementor-motion-effects-element-type-background),
.elementor-1058
  .elementor-element.elementor-element-4c106bb
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f3f3f3;
}
.elementor-1058 .elementor-element.elementor-element-4c106bb {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-1058
  .elementor-element.elementor-element-4c106bb
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1058
  .elementor-element.elementor-element-43048b7
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-1058
  .elementor-element.elementor-element-4a96e2f
  .elementor-icon-wrapper {
  text-align: left;
}
.elementor-1058
  .elementor-element.elementor-element-4a96e2f.elementor-view-stacked
  .elementor-icon {
  background-color: #eab22e;
}
.elementor-1058
  .elementor-element.elementor-element-4a96e2f.elementor-view-framed
  .elementor-icon,
.elementor-1058
  .elementor-element.elementor-element-4a96e2f.elementor-view-default
  .elementor-icon {
  color: #eab22e;
  border-color: #eab22e;
}
.elementor-1058
  .elementor-element.elementor-element-4a96e2f.elementor-view-framed
  .elementor-icon,
.elementor-1058
  .elementor-element.elementor-element-4a96e2f.elementor-view-default
  .elementor-icon
  svg {
  fill: #eab22e;
}
.elementor-1058 .elementor-element.elementor-element-4a96e2f .elementor-icon i,
.elementor-1058
  .elementor-element.elementor-element-4a96e2f
  .elementor-icon
  svg {
  transform: rotate(0deg);
}
.elementor-1058
  .elementor-element.elementor-element-543b8f5
  .elementor-text-editor {
  text-align: center;
}
.elementor-1058 .elementor-element.elementor-element-543b8f5 {
  color: #17a9fa;
  font-size: 34px;
  font-weight: 500;
  line-height: 1em;
  letter-spacing: 0px;
}
.elementor-1058
  .elementor-element.elementor-element-543b8f5
  > .elementor-widget-container {
  margin: 0 0 -54px;
}
.elementor-1058
  .elementor-element.elementor-element-0a817e3
  .elementor-text-editor {
  text-align: center;
}
.elementor-1058 .elementor-element.elementor-element-0a817e3 {
  color: #7a7a7a;
  font-size: 24px;
}
.elementor-1058
  .elementor-element.elementor-element-7c88aa5
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-1058
  .elementor-element.elementor-element-dfa316c:not(.elementor-motion-effects-element-type-background),
.elementor-1058
  .elementor-element.elementor-element-dfa316c
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../assets/images/template-for-banner-scaled-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.elementor-1058 .elementor-element.elementor-element-dfa316c {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-1058
  .elementor-element.elementor-element-dfa316c
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1058
  .elementor-element.elementor-element-d42ae5b
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-1058 .elementor-element.elementor-element-5b6ef6b {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-1058
  .elementor-element.elementor-element-5b6ef6b
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
  margin-left: 0;
}
.elementor-1058
  .elementor-element.elementor-element-5b6ef6b
  .elementor-divider {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-1058
  .elementor-element.elementor-element-cbbcc08
  .elementor-heading-title {
  color: #fff;
}
.elementor-1058
  .elementor-element.elementor-element-cbbcc08
  > .elementor-widget-container {
  margin: 0 0 18px;
}
.elementor-1058
  .elementor-element.elementor-element-69cb9bf
  > .elementor-widget-container {
  margin: 0 0 48px;
}
.elementor-1058 .elementor-element.elementor-element-a3bb1e0 .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  fill: #000;
  color: #000;
  background-color: #eab22e;
  border-radius: 50px;
  padding: 25px 60px;
}
.elementor-1058
  .elementor-element.elementor-element-9fb2caa
  .elementor-spacer-inner {
  height: 100px;
}
@media (max-width: 1024px) {
  .elementor-1058
    .elementor-element.elementor-element-1d9be91
    .elementor-spacer-inner {
    height: 100px;
  }
  .elementor-1058
    .elementor-element.elementor-element-da4446b
    .elementor-heading-title {
    font-size: 20px;
  }
  .elementor-1058
    .elementor-element.elementor-element-ac575ee
    .elementor-heading-title {
    font-size: 23px;
  }
  .elementor-1058
    .elementor-element.elementor-element-c697be1
    .elementor-spacer-inner {
    height: 25px;
  }
  .elementor-1058
    .elementor-element.elementor-element-a691743
    .elementor-spacer-inner {
    height: 75px;
  }
  .elementor-1058
    .elementor-element.elementor-element-0d3397b
    .elementor-heading-title {
    font-size: 34px;
  }
  .elementor-1058
    .elementor-element.elementor-element-d5d0e8f
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
  .elementor-1058
    .elementor-element.elementor-element-82c5286
    > .elementor-element-populated {
    margin: 0 25px 0 0;
  }
  .elementor-1058
    .elementor-element.elementor-element-ce39b65
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-1058
    .elementor-element.elementor-element-94501a6
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-1058
    .elementor-element.elementor-element-86d6f60
    > .elementor-element-populated {
    margin: 0 0 0 10px;
    padding: 0;
  }
  .elementor-1058
    .elementor-element.elementor-element-ff5421a
    .elementor-spacer-inner {
    height: 75px;
  }
  .elementor-1058
    .elementor-element.elementor-element-9a1f72d
    .elementor-heading-title {
    font-size: 34px;
  }
  .elementor-1058
    .elementor-element.elementor-element-06c516a
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
  .elementor-1058
    .elementor-element.elementor-element-5b6ef6b
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
}
@media (max-width: 767px) {
  .elementor-1058
    .elementor-element.elementor-element-1d9be91
    .elementor-spacer-inner {
    height: 75px;
  }
  .elementor-1058 .elementor-element.elementor-element-da4446b {
    text-align: left;
  }
  .elementor-1058
    .elementor-element.elementor-element-03a77d8
    .elementor-divider {
    text-align: left;
  }
  .elementor-1058
    .elementor-element.elementor-element-03a77d8
    .elementor-divider-separator {
    margin: 0 auto;
    margin-left: 0;
  }
  .elementor-1058
    .elementor-element.elementor-element-03a77d8
    > .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .elementor-1058 .elementor-element.elementor-element-ac575ee {
    text-align: left;
  }
  .elementor-1058
    .elementor-element.elementor-element-ac575ee
    .elementor-heading-title {
    font-size: 21px;
  }
  .elementor-1058
    .elementor-element.elementor-element-ac575ee
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 11px;
  }
  .elementor-1058
    .elementor-element.elementor-element-c697be1
    .elementor-spacer-inner {
    height: 30px;
  }
  .elementor-1058
    .elementor-element.elementor-element-a691743
    .elementor-spacer-inner {
    height: 75px;
  }
  .elementor-1058
    .elementor-element.elementor-element-d5d0e8f
    > .elementor-widget-container {
    padding: 0;
  }
  .elementor-1058
    .elementor-element.elementor-element-82c5286
    > .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .elementor-1058
    .elementor-element.elementor-element-dd10652
    .elementor-text-editor {
    text-align: center;
  }
  .elementor-1058
    .elementor-element.elementor-element-b21a9f4
    .elementor-text-editor {
    text-align: center;
  }
  .elementor-1058
    .elementor-element.elementor-element-173eb4f
    .elementor-button {
    font-size: 13px;
    padding: 15px 20px 17px;
  }
  .elementor-1058
    .elementor-element.elementor-element-86d6f60
    > .elementor-element-populated {
    margin: 0;
    padding: 0 0 150px;
  }
  .elementor-1058
    .elementor-element.elementor-element-06c516a
    > .elementor-widget-container {
    padding: 0;
  }
  .elementor-1058
    .elementor-element.elementor-element-43048b7
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-1058 .elementor-element.elementor-element-4a96e2f .elementor-icon {
    font-size: 17px;
  }
  .elementor-1058 .elementor-element.elementor-element-543b8f5 {
    font-size: 21px;
  }
  .elementor-1058
    .elementor-element.elementor-element-543b8f5
    > .elementor-widget-container {
    margin: 0 0 -30px;
    padding: 0 0 -15px;
  }
  .elementor-1058 .elementor-element.elementor-element-0a817e3 {
    font-size: 16px;
  }
  .elementor-1058
    .elementor-element.elementor-element-0a817e3
    > .elementor-widget-container {
    margin: 0 0 -28px;
    padding: 0;
  }
  .elementor-1058
    .elementor-element.elementor-element-7c88aa5
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-1058
    .elementor-element.elementor-element-5b6ef6b
    > .elementor-widget-container {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .elementor-1058 .elementor-element.elementor-element-82c5286 {
    width: 54.958%;
  }
  .elementor-1058 .elementor-element.elementor-element-86d6f60 {
    width: 45%;
  }
  .elementor-1058 .elementor-element.elementor-element-119db74 {
    width: 48.559%;
  }
  .elementor-1058 .elementor-element.elementor-element-3636875 {
    width: 51.441%;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .elementor-1058 .elementor-element.elementor-element-82c5286 {
    width: 50%;
  }
  .elementor-1058 .elementor-element.elementor-element-86d6f60 {
    width: 25%;
  }
}
@media (min-width: 1025px) {
  .elementor-1058
    .elementor-element.elementor-element-0eab6a0:not(.elementor-motion-effects-element-type-background),
  .elementor-1058
    .elementor-element.elementor-element-0eab6a0
    > .elementor-motion-effects-container
    > .elementor-motion-effects-layer {
    background-attachment: scroll;
  }
  .elementor-1058
    .elementor-element.elementor-element-86d6f60:not(.elementor-motion-effects-element-type-background)
    > .elementor-column-wrap,
  .elementor-1058
    .elementor-element.elementor-element-86d6f60
    > .elementor-column-wrap
    > .elementor-motion-effects-container
    > .elementor-motion-effects-layer {
    background-attachment: scroll;
  }
}

.focusing {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

.div-500 {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin: 20px auto;
  position: relative;
  background-image: linear-gradient(to right, #ffda04 0%, #ffc91e 100%);
  text-align: center;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.donate-price {
  margin-right: -75px;
  margin-bottom: 0px;
  padding: 20px;
  position: absolute;
  top: 75px;
  left: 75px;
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-weight: 800;
}

.annually {
  margin-right: -75px;
  margin-bottom: 0px;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1rem !important;
  position: absolute;
  top: 105px;
  left: 75px;
  transform: translate(-50%, -50%);
}

.to-support {
  margin-bottom: 1rem;
  font-size: 16px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
}

.donate-link {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  padding: 20px;
  font-style: normal;
}

.support-us {
  font-size: 51px;
  font-style: normal;
  font-weight: 700;
  color: rgb(255, 255, 255);
  font-family: "Trebuchet MS", sans-serif;
  text-transform: uppercase;
}

.support-us {
  font-size: 51px;
  font-style: normal;
  font-weight: 700;
  color: rgb(255, 255, 255);
  font-family: "Trebuchet MS", sans-serif;
  text-transform: uppercase;
}

.support-us {
  font-size: 51px;
  font-style: normal;
  font-weight: 700;
  color: rgb(255, 255, 255);
  font-family: "Trebuchet MS", sans-serif;
  text-transform: uppercase;
}
