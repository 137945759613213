.elementor-103
  .elementor-element.elementor-element-41724257:not(.elementor-motion-effects-element-type-background),
.elementor-103
  .elementor-element.elementor-element-41724257
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #787878;
}
.elementor-103
  .elementor-element.elementor-element-41724257
  > .elementor-background-overlay {
  opacity: 0.8;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-103 .elementor-element.elementor-element-41724257 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-103
  .elementor-element.elementor-element-72243ff9:not(.elementor-motion-effects-element-type-background)
  > .elementor-column-wrap,
.elementor-103
  .elementor-element.elementor-element-72243ff9
  > .elementor-column-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f7f7f7;
}
.elementor-103
  .elementor-element.elementor-element-72243ff9
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-103
  .elementor-element.elementor-element-72243ff9
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-103
  .elementor-element.elementor-element-f9dd4c4
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-103 .elementor-element.elementor-element-2cb87c91 {
  text-align: center;
}
.elementor-103
  .elementor-element.elementor-element-2cb87c91
  .elementor-heading-title {
  color: #000;
  font-size: 38px;
}
.elementor-103
  .elementor-element.elementor-element-2cb87c91
  > .elementor-widget-container {
  padding: 0 0 0 39px;
}
.elementor-103
  .elementor-element.elementor-element-6a0bc6f
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-103 .elementor-element.elementor-element-d7a9757 {
  padding: 0 30px;
}
.elementor-103
  .elementor-element.elementor-element-2492ff7:not(.elementor-motion-effects-element-type-background)
  > .elementor-column-wrap,
.elementor-103
  .elementor-element.elementor-element-2492ff7
  > .elementor-column-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #fff;
}
.elementor-103
  .elementor-element.elementor-element-2492ff7
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0 0 0 26px;
}
.elementor-103
  .elementor-element.elementor-element-2492ff7
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-103
  .elementor-element.elementor-element-6bf0b5e
  .elementor-heading-title {
  font-size: 27px;
}
.elementor-103
  .elementor-element.elementor-element-6bf0b5e
  > .elementor-widget-container {
  margin: 19px 0 -4px;
}
.elementor-103
  .elementor-element.elementor-element-0db7911
  .elementor-text-editor {
  text-align: center;
}
.elementor-103
  .elementor-element.elementor-element-0db7911
  > .elementor-widget-container {
  margin: 0 0 -28px;
}
body:not(.rtl) .elementor-103 .elementor-element.elementor-element-0db7911 {
  left: 215px;
}
body.rtl .elementor-103 .elementor-element.elementor-element-0db7911 {
  right: 215px;
}
.elementor-103 .elementor-element.elementor-element-0db7911 {
  top: 63px;
}
.elementor-103
  .elementor-element.elementor-element-1fa12ca
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-103
  .elementor-element.elementor-element-c588695
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-103
  .elementor-element.elementor-element-8261bf3:not(.elementor-motion-effects-element-type-background)
  > .elementor-column-wrap,
.elementor-103
  .elementor-element.elementor-element-8261bf3
  > .elementor-column-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #fff;
}
.elementor-103
  .elementor-element.elementor-element-8261bf3
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-103
  .elementor-element.elementor-element-8261bf3
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-103
  .elementor-element.elementor-element-b121a97
  .elementor-spacer-inner {
  height: 168px;
}
.elementor-103
  .elementor-element.elementor-element-201a562
  .elementor-spacer-inner {
  height: 132px;
}
.elementor-103
  .elementor-element.elementor-element-0adde57
  .elementor-spacer-inner {
  height: 121px;
}
.elementor-103
  .elementor-element.elementor-element-71e75e2:not(.elementor-motion-effects-element-type-background)
  > .elementor-column-wrap,
.elementor-103
  .elementor-element.elementor-element-71e75e2
  > .elementor-column-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #fff;
}
.elementor-103
  .elementor-element.elementor-element-71e75e2
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0 20px;
}
.elementor-103
  .elementor-element.elementor-element-71e75e2
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-103
  .elementor-element.elementor-element-e891e34
  .elementor-spacer-inner {
  height: 13px;
}
.elementor-103
  .elementor-element.elementor-element-4be2b74
  .elementor-spacer-inner {
  height: 27px;
}
.elementor-103 .elementor-element.elementor-element-9c07a92 {
  text-align: center;
}
.elementor-103
  .elementor-element.elementor-element-97dabc1
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-103
  .elementor-element.elementor-element-5ccc067
  .elementor-tab-title,
.elementor-103
  .elementor-element.elementor-element-5ccc067
  .elementor-tab-title:before,
.elementor-103
  .elementor-element.elementor-element-5ccc067
  .elementor-tab-title:after,
.elementor-103
  .elementor-element.elementor-element-5ccc067
  .elementor-tab-content,
.elementor-103
  .elementor-element.elementor-element-5ccc067
  .elementor-tabs-content-wrapper {
  border-width: 1px;
}
.elementor-103
  .elementor-element.elementor-element-5ccc067
  .elementor-tab-title.elementor-active
  a {
  color: #d3b237;
}
.elementor-103
  .elementor-element.elementor-element-99b451f
  .elementor-text-editor {
  text-align: center;
}
.elementor-103 .elementor-element.elementor-element-99b451f {
  color: #54595f;
  font-family: "Tahoma", Sans-serif;
  font-weight: 400;
}
.elementor-103
  .elementor-element.elementor-element-99b451f
  > .elementor-widget-container {
  margin: 37px 0 0;
}
.elementor-103
  .elementor-element.elementor-element-2fc94ae9
  .elementor-spacer-inner {
  height: 100px;
}
body.elementor-page-103:not(.elementor-motion-effects-element-type-background),
body.elementor-page-103
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #fff;
}
@media (min-width: 768px) {
  .elementor-103 .elementor-element.elementor-element-2492ff7 {
    width: 42.193%;
  }
  .elementor-103 .elementor-element.elementor-element-8261bf3 {
    width: 24.111%;
  }
}
@media (max-width: 1024px) {
  .elementor-103
    .elementor-element.elementor-element-2fc94ae9
    .elementor-spacer-inner {
    height: 90px;
  }
}
@media (max-width: 767px) {
  .elementor-103
    .elementor-element.elementor-element-2fc94ae9
    .elementor-spacer-inner {
    height: 40px;
  }
}
