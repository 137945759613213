img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}

html {
  font-size: 93.75%;
}
a,
.page-title {
  color: #eab22e;
}
a:hover,
a:focus {
  color: #feca2b;
}
body,
button,
input,
select,
textarea {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  font-size: 1rem;
}
blockquote {
  color: #000000;
}
h1,
.entry-content h1,
.entry-content h1 a,
h2,
.entry-content h2,
.entry-content h2 a,
h3,
.entry-content h3,
.entry-content h3 a,
h4,
.entry-content h4,
.entry-content h4 a,
h5,
.entry-content h5,
.entry-content h5 a,
h6,
.entry-content h6,
.entry-content h6 a,
.site-title,
.site-title a {
  font-family: "Bebas Neue", display;
  font-weight: 400;
  text-transform: none;
}
.site-title {
  font-size: 35px;
  font-size: 2.3333333333333rem;
}
header .site-logo-img .custom-logo-link img {
  max-width: 308px;
}
.astra-logo-svg {
  width: 308px;
}
.ast-archive-description .ast-archive-title {
  font-size: 40px;
  font-size: 2.6666666666667rem;
}
.site-header .site-description {
  font-size: 15px;
  font-size: 1rem;
}
.entry-title {
  font-size: 30px;
  font-size: 2rem;
}
.comment-reply-title {
  font-size: 24px;
  font-size: 1.6rem;
}
.ast-comment-list #cancel-comment-reply-link {
  font-size: 15px;
  font-size: 1rem;
}
h1,
.entry-content h1,
.entry-content h1 a {
  font-size: 96px;
  font-size: 6.4rem;
  font-weight: 400;
  font-family: "Bebas Neue", display;
  line-height: 1.14;
  text-transform: uppercase;
}
h2,
.entry-content h2,
.entry-content h2 a {
  font-size: 38px;
  font-size: 2.5333333333333rem;
  font-weight: 400;
  font-family: "Bebas Neue", display;
  line-height: 1.4;
  text-transform: uppercase;
}
h3,
.entry-content h3,
.entry-content h3 a {
  font-size: 28px;
  font-size: 1.8666666666667rem;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  line-height: 1.4;
  text-transform: none;
}
h4,
.entry-content h4,
.entry-content h4 a {
  font-size: 21px;
  font-size: 1.4rem;
}
h5,
.entry-content h5,
.entry-content h5 a {
  font-size: 16px;
  font-size: 1.0666666666667rem;
}
h6,
.entry-content h6,
.entry-content h6 a {
  font-size: 14px;
  font-size: 0.93333333333333rem;
}
.ast-single-post .entry-title,
.page-title {
  font-size: 30px;
  font-size: 2rem;
}
#secondary,
#secondary button,
#secondary input,
#secondary select,
#secondary textarea {
  font-size: 15px;
  font-size: 1rem;
}
::selection {
  background-color: #ee0979;
  color: #ffffff;
}
body,
h1,
.entry-title a,
.entry-content h1,
.entry-content h1 a,
h2,
.entry-content h2,
.entry-content h2 a,
h3,
.entry-content h3,
.entry-content h3 a,
h4,
.entry-content h4,
.entry-content h4 a,
h5,
.entry-content h5,
.entry-content h5 a,
h6,
.entry-content h6,
.entry-content h6 a {
  color: #000000;
}
.tagcloud a:hover,
.tagcloud a:focus,
.tagcloud a.current-item {
  color: #000000;
  border-color: #eab22e;
  background-color: #eab22e;
}
.main-header-menu a,
.ast-header-custom-item a {
  color: #000000;
}
.main-header-menu li:hover > a,
.main-header-menu li:hover > .ast-menu-toggle,
.main-header-menu .ast-masthead-custom-menu-items a:hover,
.main-header-menu li.focus > a,
.main-header-menu li.focus > .ast-menu-toggle,
.main-header-menu .current-menu-item > a,
.main-header-menu .current-menu-ancestor > a,
.main-header-menu .current_page_item > a,
.main-header-menu .current-menu-item > .ast-menu-toggle,
.main-header-menu .current-menu-ancestor > .ast-menu-toggle,
.main-header-menu .current_page_item > .ast-menu-toggle {
  color: #eab22e;
}
input:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="reset"]:focus,
input[type="search"]:focus,
textarea:focus {
  border-color: #eab22e;
}
input[type="radio"]:checked,
input[type="reset"],
input[type="checkbox"]:checked,
input[type="checkbox"]:hover:checked,
input[type="checkbox"]:focus:checked,
input[type="range"]::-webkit-slider-thumb {
  border-color: #eab22e;
  background-color: #eab22e;
  box-shadow: none;
}
.site-footer a:hover + .post-count,
.site-footer a:focus + .post-count {
  background: #eab22e;
  border-color: #eab22e;
}
.ast-small-footer {
  color: #d3d3d3;
}
.ast-small-footer > .ast-footer-overlay {
  background-color: #2d2d2d;
}
.ast-small-footer a {
  color: #7a7a7a;
}
.ast-small-footer a:hover {
  color: #ee0979;
}
.footer-adv .footer-adv-overlay {
  border-top-style: solid;
  border-top-width: 0;
  border-top-color: #7a7a7a;
}
.ast-comment-meta {
  line-height: 1.666666667;
  font-size: 12px;
  font-size: 0.8rem;
}
.single .nav-links .nav-previous,
.single .nav-links .nav-next,
.single .ast-author-details .author-title,
.ast-comment-meta {
  color: #eab22e;
}
.entry-meta,
.entry-meta * {
  line-height: 1.45;
  color: #eab22e;
}
.entry-meta a:hover,
.entry-meta a:hover *,
.entry-meta a:focus,
.entry-meta a:focus * {
  color: #feca2b;
}
.ast-404-layout-1 .ast-404-text {
  font-size: 200px;
  font-size: 13.333333333333rem;
}
.widget-title {
  font-size: 21px;
  font-size: 1.4rem;
  color: #000000;
}
#cat option,
.secondary .calendar_wrap thead a,
.secondary .calendar_wrap thead a:visited {
  color: #eab22e;
}
.secondary .calendar_wrap #today,
.ast-progress-val span {
  background: #eab22e;
}
.secondary a:hover + .post-count,
.secondary a:focus + .post-count {
  background: #eab22e;
  border-color: #eab22e;
}
.calendar_wrap #today > a {
  color: #000000;
}
.ast-pagination a,
.page-links .page-link,
.single .post-navigation a {
  color: #eab22e;
}
.ast-pagination a:hover,
.ast-pagination a:focus,
.ast-pagination > span:hover:not(.dots),
.ast-pagination > span.current,
.page-links > .page-link,
.page-links .page-link:hover,
.post-navigation a:hover {
  color: #feca2b;
}
.ast-header-break-point .ast-mobile-menu-buttons-minimal.menu-toggle {
  background: transparent;
  color: #f2b22e;
}
.ast-header-break-point .ast-mobile-menu-buttons-outline.menu-toggle {
  background: transparent;
  border: 1px solid #f2b22e;
  color: #f2b22e;
}
.ast-header-break-point .ast-mobile-menu-buttons-fill.menu-toggle {
  background: #f2b22e;
  color: #ffffff;
}
.elementor-button-wrapper .elementor-button,
.elementor-button-wrapper .elementor-button:visited,
.wp-block-button .wp-block-button__link {
  border-style: solid;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
  color: #ffffff;
  border-color: #f2b22e;
  background-color: #f2b22e;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  line-height: 1;
  text-transform: uppercase;
}
.elementor-button-wrapper .elementor-button.elementor-size-sm,
.elementor-button-wrapper .elementor-button.elementor-size-xs,
.elementor-button-wrapper .elementor-button.elementor-size-md,
.elementor-button-wrapper .elementor-button.elementor-size-lg,
.elementor-button-wrapper .elementor-button.elementor-size-xl,
.elementor-button-wrapper .elementor-button,
.wp-block-button .wp-block-button__link {
  font-size: 15px;
  font-size: 1rem;
  border-radius: 50px;
  padding-top: 17px;
  padding-right: 30px;
  padding-bottom: 17px;
  padding-left: 30px;
}
.elementor-button-wrapper .elementor-button:hover,
.elementor-button-wrapper .elementor-button:focus,
.wp-block-button .wp-block-button__link:hover,
.wp-block-button .wp-block-button__link:focus {
  color: #ffffff;
  background-color: #f2a100;
  border-color: #f2a100;
}
.elementor-widget-heading h1.elementor-heading-title {
  line-height: 1.14;
}
.elementor-widget-heading h2.elementor-heading-title {
  line-height: 1.4;
}
.elementor-widget-heading h3.elementor-heading-title {
  line-height: 1.4;
}
.menu-toggle,
button,
.ast-button,
.button,
input#submit,
input[type="button"],
input[type="submit"],
input[type="reset"] {
  border-style: solid;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
  color: #ffffff;
  border-color: #f2b22e;
  background-color: #f2b22e;
  border-radius: 50px;
  padding-top: 17px;
  padding-right: 30px;
  padding-bottom: 17px;
  padding-left: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-size: 15px;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
}
button:focus,
.menu-toggle:hover,
button:hover,
.ast-button:hover,
.button:hover,
input[type="reset"]:hover,
input[type="reset"]:focus,
input#submit:hover,
input#submit:focus,
input[type="button"]:hover,
input[type="button"]:focus,
input[type="submit"]:hover,
input[type="submit"]:focus {
  color: #ffffff;
  background-color: #f2a100;
  border-color: #f2a100;
}
@media (min-width: 545px) {
  .ast-page-builder-template .comments-area,
  .single.ast-page-builder-template .entry-header,
  .single.ast-page-builder-template .post-navigation {
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
  }
}
body,
.ast-separate-container {
  background-color: #f7f7f7;
}
@media (max-width: 768px) {
  .ast-archive-description .ast-archive-title {
    font-size: 40px;
  }
  .entry-title {
    font-size: 30px;
  }
  h1,
  .entry-content h1,
  .entry-content h1 a {
    font-size: 70px;
  }
  h2,
  .entry-content h2,
  .entry-content h2 a {
    font-size: 25px;
  }
  h3,
  .entry-content h3,
  .entry-content h3 a {
    font-size: 20px;
  }
  .ast-single-post .entry-title,
  .page-title {
    font-size: 30px;
  }
}
@media (max-width: 544px) {
  .ast-archive-description .ast-archive-title {
    font-size: 40px;
  }
  .entry-title {
    font-size: 30px;
  }
  h1,
  .entry-content h1,
  .entry-content h1 a {
    font-size: 45px;
  }
  h2,
  .entry-content h2,
  .entry-content h2 a {
    font-size: 25px;
  }
  h3,
  .entry-content h3,
  .entry-content h3 a {
    font-size: 20px;
  }
  .ast-single-post .entry-title,
  .page-title {
    font-size: 30px;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 85.5%;
  }
}
@media (max-width: 544px) {
  html {
    font-size: 85.5%;
  }
}
@media (min-width: 769px) {
  .ast-container-checkout {
    max-width: 1240px !important;
  }
}
@font-face {
  font-family: "Astra";
  src: url("../../assets/fonts/astra.woff")
      format("woff"),
    url("../../assets/fonts/astra.ttf")
      format("truetype"),
    url("../../assets/fonts/astra.svg#astra")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@media (max-width: 921px) {
  .main-header-bar .main-header-bar-navigation {
    display: none;
  }
}
.ast-desktop .main-header-menu.submenu-with-border .sub-menu,
.ast-desktop .main-header-menu.submenu-with-border .children,
.ast-desktop
  .main-header-menu.submenu-with-border
  .astra-full-megamenu-wrapper {
  border-color: #eaeaea;
}
.ast-desktop .main-header-menu.submenu-with-border .sub-menu,
.ast-desktop .main-header-menu.submenu-with-border .children {
  border-top-width: 1px;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
}
.ast-desktop .main-header-menu.submenu-with-border .sub-menu .sub-menu,
.ast-desktop .main-header-menu.submenu-with-border .children .children {
  top: -1px;
}
.ast-desktop .main-header-menu.submenu-with-border .sub-menu a,
.ast-desktop .main-header-menu.submenu-with-border .children a {
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #eaeaea;
}
@media (min-width: 769px) {
  .main-header-menu .sub-menu li.ast-left-align-sub-menu:hover > ul,
  .main-header-menu .sub-menu li.ast-left-align-sub-menu.focus > ul {
    margin-left: -2px;
  }
}
.ast-small-footer {
  border-top-style: solid;
  border-top-width: 0;
  border-top-color: #fde6f1;
}
@media (max-width: 920px) {
  .ast-404-layout-1 .ast-404-text {
    font-size: 100px;
    font-size: 6.6666666666667rem;
  }
}
.ast-breadcrumbs .trail-browse,
.ast-breadcrumbs .trail-items,
.ast-breadcrumbs .trail-items li {
  display: inline-block;
  margin: 0;
  padding: 0;
  border: none;
  background: inherit;
  text-indent: 0;
}
.ast-breadcrumbs .trail-browse {
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
}
.ast-breadcrumbs .trail-items {
  list-style: none;
}
.trail-items li::after {
  padding: 0 0.3em;
  content: "»";
}
.trail-items li:last-of-type::after {
  display: none;
}
h1,
.entry-content h1,
h2,
.entry-content h2,
h3,
.entry-content h3,
h4,
.entry-content h4,
h5,
.entry-content h5,
h6,
.entry-content h6 {
  color: #161616;
}
.ast-header-break-point .site-header {
  border-bottom-width: 1px;
  border-bottom-color: #fed513;
}
@media (min-width: 769px) {
  .main-header-bar {
    border-bottom-width: 1px;
    border-bottom-color: #fed513;
  }
}
.ast-flex {
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.main-header-bar {
  padding: 1em 0;
}
.ast-site-identity {
  padding: 0;
}
.header-main-layout-1 .ast-flex.main-header-container,
.header-main-layout-3 .ast-flex.main-header-container {
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-main-layout-1 .ast-flex.main-header-container,
.header-main-layout-3 .ast-flex.main-header-container {
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* SECOND GROUP */
.woocommerce span.onsale,
.wc-block-grid__product .wc-block-grid__product-onsale {
  background-color: #ee0979;
  color: #ffffff;
}
.woocommerce a.button,
.woocommerce button.button,
.woocommerce .woocommerce-message a.button,
.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt,
.woocommerce input.button,
.woocommerce input.button:disabled,
.woocommerce input.button:disabled[disabled],
.woocommerce input.button:disabled:hover,
.woocommerce input.button:disabled[disabled]:hover,
.woocommerce #respond input#submit,
.woocommerce button.button.alt.disabled,
.wc-block-grid__products .wc-block-grid__product .wp-block-button__link,
.wc-block-grid__product-onsale {
  color: #ffffff;
  border-color: #f2b22e;
  background-color: #f2b22e;
}
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce .woocommerce-message a.button:hover,
.woocommerce #respond input#submit:hover,
.woocommerce #respond input#submit.alt:hover,
.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover,
.woocommerce input.button.alt:hover,
.woocommerce input.button:hover,
.woocommerce button.button.alt.disabled:hover,
.wc-block-grid__products .wc-block-grid__product .wp-block-button__link:hover {
  color: #ffffff;
  border-color: #f2a100;
  background-color: #f2a100;
}
.woocommerce-message,
.woocommerce-info {
  border-top-color: #eab22e;
}
.woocommerce-message::before,
.woocommerce-info::before {
  color: #eab22e;
}
.woocommerce ul.products li.product .price,
.woocommerce div.product p.price,
.woocommerce div.product span.price,
.widget_layered_nav_filters ul li.chosen a,
.woocommerce-page ul.products li.product .ast-woo-product-category,
.wc-layered-nav-rating a {
  color: #000000;
}
.woocommerce nav.woocommerce-pagination ul,
.woocommerce nav.woocommerce-pagination ul li {
  border-color: #eab22e;
}
.woocommerce nav.woocommerce-pagination ul li a:focus,
.woocommerce nav.woocommerce-pagination ul li a:hover,
.woocommerce nav.woocommerce-pagination ul li span.current {
  background: #eab22e;
  color: #ffffff;
}
.woocommerce-MyAccount-navigation-link.is-active a {
  color: #feca2b;
}
.woocommerce .widget_price_filter .ui-slider .ui-slider-range,
.woocommerce .widget_price_filter .ui-slider .ui-slider-handle {
  background-color: #eab22e;
}
.woocommerce a.button,
.woocommerce button.button,
.woocommerce .woocommerce-message a.button,
.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt,
.woocommerce input.button,
.woocommerce-cart table.cart td.actions .button,
.woocommerce form.checkout_coupon .button,
.woocommerce #respond input#submit,
.wc-block-grid__products .wc-block-grid__product .wp-block-button__link {
  border-radius: 50px;
  padding-top: 17px;
  padding-right: 30px;
  padding-bottom: 17px;
  padding-left: 30px;
}
.woocommerce .star-rating,
.woocommerce .comment-form-rating .stars a,
.woocommerce .star-rating::before {
  color: #eab22e;
}
.woocommerce div.product .woocommerce-tabs ul.tabs li.active:before {
  background: #eab22e;
}
.ast-site-header-cart a {
  color: #000000;
}
.ast-site-header-cart a:focus,
.ast-site-header-cart a:hover,
.ast-site-header-cart .current-menu-item a {
  color: #eab22e;
}
.ast-cart-menu-wrap .count,
.ast-cart-menu-wrap .count:after {
  border-color: #eab22e;
  color: #eab22e;
}
.ast-cart-menu-wrap:hover .count {
  color: #000000;
  background-color: #eab22e;
}
.ast-site-header-cart .widget_shopping_cart .total .woocommerce-Price-amount {
  color: #eab22e;
}
.woocommerce a.remove:hover,
.ast-woocommerce-cart-menu
  .main-header-menu
  .woocommerce-custom-menu-item
  li:hover
  > a.remove:hover {
  color: #eab22e;
  border-color: #eab22e;
  background-color: #ffffff;
}
.ast-site-header-cart .widget_shopping_cart .buttons .button.checkout,
.woocommerce
  .widget_shopping_cart
  .woocommerce-mini-cart__buttons
  .checkout.wc-forward {
  color: #ffffff;
  border-color: #f2a100;
  background-color: #f2a100;
}
.site-header .ast-site-header-cart-data .button.wc-forward,
.site-header .ast-site-header-cart-data .button.wc-forward:hover {
  color: #ffffff;
}
.below-header-user-select .ast-site-header-cart .widget,
.ast-above-header-section .ast-site-header-cart .widget a,
.below-header-user-select .ast-site-header-cart .widget_shopping_cart a {
  color: #000000;
}
.below-header-user-select .ast-site-header-cart .widget_shopping_cart a:hover,
.ast-above-header-section .ast-site-header-cart .widget_shopping_cart a:hover,
.below-header-user-select
  .ast-site-header-cart
  .widget_shopping_cart
  a.remove:hover,
.ast-above-header-section
  .ast-site-header-cart
  .widget_shopping_cart
  a.remove:hover {
  color: #eab22e;
}
@media (min-width: 769px) {
  .site-content > .ast-container-checkout {
    max-width: 1240px !important;
  }
}

.ast-container-checkout {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

form #order_review,
form #order_review {
  padding: 0 2em;
  border-width: 0 2px 2px;
  border-style: solid;
  border-color: #ebebeb;
}

form #order_review_heading,
form #order_review_heading {
  border-width: 2px 2px 0 2px;
  border-style: solid;
  font-size: 1.2rem;
  margin: 0;
  padding: 1.5em 1.5em 1em;
  border-color: #ebebeb;
}
.woocommerce-checkout{
    margin-bottom: 30px;
}

form #order_review table{
    border: none;
}
form #order_review td, form #order_review th {
    border-top: 0;
    border-right: 0;
    padding-left: 0;
    border-color: #ebebeb;
}

/* THIRD GROUP */
.donate-hover > .elementor-widget-container:hover {
  background-color: #5d5d5d !important;
  color: #fecf2c !important;
  cursor: pointer;
}

#logo-size {
  color: #eab22e;
  margin-right: 5px;
  font-size: 17px;
}

.current-menu-item a {
  color: #eab22e;
}

#cn-logo {
  font-size: 24px;
  padding-right: 10px;
  cursor: pointer;
}

h2.widget-title {
  color: #eab22e !important;
}

#cn-logo:hover {
  color: #eab22e;
}

div#customer_details {
  display: none;
}

div#payment {
  visibility: hidden;
}

tr.cart-subtotal {
  display: none;
}

.post-1125 .entry-content .woocommerce {
  position: absolute;
  width: 100%;
}

/* footer */

.footer-adv-overlay {
  padding-bottom: 27px;
}

.ast-footer-overlay {
  padding: 20px;
}

/* footer end */
/* contact Us */
.elementor-element.elementor-element-6b698c7.elementor-widget.elementor-widget-text-editor
  > div {
  margin-top: -51px;
}
/* contact us end  */

@media screen and (max-width: 425px) {
  .wp-block-columns {
    margin-top: 0px !important;
  }
}

/* end  */
li p {
  line-height: 0;
}

.wp-block-spacer-long {
  height: 285px;
}
.wp-block-column {
  flex-basis: 41%;
}
.wp-block-column-large {
  flex-basis: 59%;
}
.wp-block-column.content-item > figure > img {
  max-height: 372px;
  height: 100%;
}

ul.item-list {
  list-style: none;
  margin-left: 0px;
}

p.header-h2 {
  /* margin-top: 17px; */
  margin-bottom: 10px;
}

p.has-medium-font-size.item-header {
  margin-bottom: 8px;
}

figure.wp-block-image.size-large.content-item {
  height: 363px;
}
