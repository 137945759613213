.elementor-1078
  .elementor-element.elementor-element-e711c87:not(.elementor-motion-effects-element-type-background),
.elementor-1078
  .elementor-element.elementor-element-e711c87
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../assets/images/banner-1024x767-1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.elementor-1078
  .elementor-element.elementor-element-e711c87
  > .elementor-background-overlay {
  background-color: rgba(0, 0, 0, 0.83);
  opacity: 0.8;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1078 .elementor-element.elementor-element-e711c87 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-1078
  .elementor-element.elementor-element-3704794
  .elementor-spacer-inner {
  height: 214px;
}
.elementor-1078 .elementor-element.elementor-element-d337612 {
  text-align: left;
}
.elementor-1078
  .elementor-element.elementor-element-d337612
  .elementor-heading-title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}
.elementor-1078
  .elementor-element.elementor-element-d337612
  > .elementor-widget-container {
  margin: 0 0 -18px;
}
.elementor-1078 .elementor-element.elementor-element-d658566 {
  --divider-border-style: solid;
  --divider-border-color: #f2b22e;
  --divider-border-width: 5px;
}
.elementor-1078
  .elementor-element.elementor-element-d658566
  .elementor-divider-separator {
  width: 100px;
  margin: 0 auto;
  margin-left: 0;
}
.elementor-1078
  .elementor-element.elementor-element-d658566
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.elementor-1078
  .elementor-element.elementor-element-d658566
  > .elementor-widget-container {
  padding: 0;
}
.elementor-1078 .elementor-element.elementor-element-113b542 {
  text-align: left;
}
.elementor-1078
  .elementor-element.elementor-element-113b542
  .elementor-heading-title {
  color: #fff;
  font-size: 35px;
}
.elementor-1078
  .elementor-element.elementor-element-113b542
  > .elementor-widget-container {
  padding: 0 0 50px;
}
.elementor-1078
  .elementor-element.elementor-element-7c95718
  .elementor-spacer-inner {
  height: 150px;
}
.elementor-1078
  .elementor-element.elementor-element-f453be9:not(.elementor-motion-effects-element-type-background),
.elementor-1078
  .elementor-element.elementor-element-f453be9
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f3f3f3;
}
.elementor-1078 .elementor-element.elementor-element-f453be9 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-1078
  .elementor-element.elementor-element-f453be9
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1078
  .elementor-element.elementor-element-9b74238
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-1078 .elementor-element.elementor-element-9746134 {
  text-align: center;
}
.elementor-1078
  .elementor-element.elementor-element-9746134
  > .elementor-widget-container {
  margin: 0 0 -25px;
}
.elementor-1078 .elementor-element.elementor-element-38a6d06 {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-1078
  .elementor-element.elementor-element-38a6d06
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
}
.elementor-1078
  .elementor-element.elementor-element-38a6d06
  .elementor-divider {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-1078 .elementor-element.elementor-element-1264645 {
  color: #54595f;
  font-size: 17px;
}
.elementor-1078 .elementor-element.elementor-element-a1dd312 .elementor-button {
  font-size: 22px;
  text-transform: uppercase;
  background-color: #eab22e;
  border-radius: 15px;
}
.elementor-1078
  .elementor-element.elementor-element-22dfc08
  .elementor-image
  img {
  width: 100%;
  border-style: solid;
  border-width: 7px;
  border-color: #fff;
  border-radius: 35px;
  box-shadow: 9px 5px 10px 0px rgba(0, 0, 0, 0.5);
}
.elementor-1078
  .elementor-element.elementor-element-c61e3dc
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-1078
  .elementor-element.elementor-element-40c6143:not(.elementor-motion-effects-element-type-background),
.elementor-1078
  .elementor-element.elementor-element-40c6143
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #fff;
}
.elementor-1078 .elementor-element.elementor-element-40c6143 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-1078
  .elementor-element.elementor-element-40c6143
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1078
  .elementor-element.elementor-element-24d311a
  .elementor-spacer-inner {
  height: 123px;
}
.elementor-1078
  .elementor-element.elementor-element-2d66349
  .elementor-image
  img {
  border-style: solid;
  border-width: 7px;
  border-color: #fff;
  border-radius: 35px;
  box-shadow: 5px 8px 10px 0px rgba(0, 0, 0, 0.5);
}
.elementor-1078
  .elementor-element.elementor-element-aaf640e
  .elementor-spacer-inner {
  height: 79px;
}
.elementor-1078
  .elementor-element.elementor-element-60a5284
  > .elementor-element-populated {
  padding: 0 0 0 15px;
}
.elementor-1078
  .elementor-element.elementor-element-b4fa034
  .elementor-spacer-inner {
  height: 123px;
}
.elementor-1078
  .elementor-element.elementor-element-e878f75
  > .elementor-widget-container {
  margin: 0 0 -26px;
}
.elementor-1078 .elementor-element.elementor-element-8fbc28d {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-1078
  .elementor-element.elementor-element-8fbc28d
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
  margin-left: 0;
}
.elementor-1078
  .elementor-element.elementor-element-8fbc28d
  .elementor-divider {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-1078 .elementor-element.elementor-element-19960a2 {
  color: #545454;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7em;
}
.elementor-1078 .elementor-element.elementor-element-8e742c2 .elementor-button {
  font-size: 22px;
  text-transform: uppercase;
  background-color: #eab22e;
  border-radius: 15px;
}
.elementor-1078
  .elementor-element.elementor-element-9659343:not(.elementor-motion-effects-element-type-background),
.elementor-1078
  .elementor-element.elementor-element-9659343
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #fff;
}
.elementor-1078 .elementor-element.elementor-element-9659343 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-1078
  .elementor-element.elementor-element-9659343
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1078
  .elementor-element.elementor-element-18b09a6
  > .elementor-element-populated {
  padding: 0 0 0 15px;
}
.elementor-1078
  .elementor-element.elementor-element-ca370bc
  .elementor-spacer-inner {
  height: 79px;
}
.elementor-1078
  .elementor-element.elementor-element-69a3e97
  > .elementor-widget-container {
  margin: 0 0 -25px;
}
.elementor-1078 .elementor-element.elementor-element-ee05669 {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-1078
  .elementor-element.elementor-element-ee05669
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
  margin-left: 0;
}
.elementor-1078
  .elementor-element.elementor-element-ee05669
  .elementor-divider {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-1078 .elementor-element.elementor-element-7614a5e {
  color: #545454;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7em;
}
.elementor-1078 .elementor-element.elementor-element-dc35fc8 .elementor-button {
  font-size: 22px;
  text-transform: uppercase;
  background-color: #eab22e;
  border-radius: 15px;
}
.elementor-1078
  .elementor-element.elementor-element-0484851
  .elementor-spacer-inner {
  height: 40px;
}
.elementor-1078
  .elementor-element.elementor-element-1daf8e0
  .elementor-image
  img {
  border-style: solid;
  border-width: 7px;
  border-color: #fff;
  border-radius: 35px;
  box-shadow: 5px 8px 10px 0px rgba(0, 0, 0, 0.5);
}
.elementor-1078
  .elementor-element.elementor-element-3372e45
  .elementor-spacer-inner {
  height: 57px;
}
.elementor-1078
  .elementor-element.elementor-element-fe0fb8c:not(.elementor-motion-effects-element-type-background),
.elementor-1078
  .elementor-element.elementor-element-fe0fb8c
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #fff;
}
.elementor-1078 .elementor-element.elementor-element-fe0fb8c {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-1078
  .elementor-element.elementor-element-fe0fb8c
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1078
  .elementor-element.elementor-element-928cea8
  .elementor-spacer-inner {
  height: 40px;
}
.elementor-1078
  .elementor-element.elementor-element-3803c64
  .elementor-image
  img {
  width: 91%;
  border-style: solid;
  border-width: 7px;
  border-color: #fff;
  border-radius: 35px;
  box-shadow: 5px 8px 10px 0px rgba(0, 0, 0, 0.5);
}
.elementor-1078
  .elementor-element.elementor-element-24d84f1
  .elementor-spacer-inner {
  height: 123px;
}
.elementor-1078
  .elementor-element.elementor-element-157fcfe
  > .elementor-element-populated {
  padding: 0 0 0 15px;
}
.elementor-1078
  .elementor-element.elementor-element-e3428a1
  .elementor-spacer-inner {
  height: 90px;
}
.elementor-1078
  .elementor-element.elementor-element-dfdcb05
  > .elementor-widget-container {
  margin: 0 0 -26px;
}
.elementor-1078 .elementor-element.elementor-element-4b2f7ae {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-1078
  .elementor-element.elementor-element-4b2f7ae
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
  margin-left: 0;
}
.elementor-1078
  .elementor-element.elementor-element-4b2f7ae
  .elementor-divider {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-1078 .elementor-element.elementor-element-8ade4a3 {
  color: #545454;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7em;
}
.elementor-1078 .elementor-element.elementor-element-5d984f3 .elementor-button {
  font-size: 22px;
  text-transform: uppercase;
  background-color: #eab22e;
  border-radius: 15px;
}
.elementor-1078
  .elementor-element.elementor-element-1f05fcb:not(.elementor-motion-effects-element-type-background),
.elementor-1078
  .elementor-element.elementor-element-1f05fcb
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f3f3f3;
}
.elementor-1078 .elementor-element.elementor-element-1f05fcb {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-1078
  .elementor-element.elementor-element-1f05fcb
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1078
  .elementor-element.elementor-element-05d0bbe
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-1078 .elementor-element.elementor-element-ed9cbc0 {
  text-align: center;
}
.elementor-1078
  .elementor-element.elementor-element-ed9cbc0
  > .elementor-widget-container {
  margin: 0 0 -26px;
}
.elementor-1078 .elementor-element.elementor-element-2c8a178 {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-1078
  .elementor-element.elementor-element-2c8a178
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
}
.elementor-1078
  .elementor-element.elementor-element-2c8a178
  .elementor-divider {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-1078 .elementor-element.elementor-element-dab8df2 {
  color: #7a7a7a;
}
.elementor-1078 .elementor-element.elementor-element-274b155 {
  color: #7a7a7a;
}
.elementor-1078 .elementor-element.elementor-element-61b2ae0 {
  color: #7a7a7a;
}
.elementor-1078
  .elementor-element.elementor-element-940ca8f
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-1078
  .elementor-element.elementor-element-9e2fbe5:not(.elementor-motion-effects-element-type-background),
.elementor-1078
  .elementor-element.elementor-element-9e2fbe5
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../assets/images/template-for-banner-scaled-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.elementor-1078 .elementor-element.elementor-element-9e2fbe5 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-1078
  .elementor-element.elementor-element-9e2fbe5
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1078
  .elementor-element.elementor-element-b3fb47b
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-1078 .elementor-element.elementor-element-f8b2737 {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-1078
  .elementor-element.elementor-element-f8b2737
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
  margin-left: 0;
}
.elementor-1078
  .elementor-element.elementor-element-f8b2737
  .elementor-divider {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-1078
  .elementor-element.elementor-element-cf99d10
  .elementor-heading-title {
  color: #fff;
}
.elementor-1078
  .elementor-element.elementor-element-cf99d10
  > .elementor-widget-container {
  margin: 0 0 18px;
}
.elementor-1078
  .elementor-element.elementor-element-a6125d2
  > .elementor-widget-container {
  margin: 0 0 48px;
}
.elementor-1078 .elementor-element.elementor-element-7d58a62 .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  fill: #000;
  color: #000;
  background-color: #eab22e;
  border-radius: 50px;
  padding: 25px 60px;
}
.elementor-1078
  .elementor-element.elementor-element-d44b941
  .elementor-spacer-inner {
  height: 100px;
}
@media (min-width: 768px) {
  .elementor-1078 .elementor-element.elementor-element-0239e93 {
    width: 58.559%;
  }
  .elementor-1078 .elementor-element.elementor-element-19b0e68 {
    width: 41.399%;
  }
  .elementor-1078 .elementor-element.elementor-element-0679782 {
    width: 41.667%;
  }
  .elementor-1078 .elementor-element.elementor-element-60a5284 {
    width: 58.333%;
  }
  .elementor-1078 .elementor-element.elementor-element-18b09a6 {
    width: 54.833%;
  }
  .elementor-1078 .elementor-element.elementor-element-1a4d107 {
    width: 45.167%;
  }
  .elementor-1078 .elementor-element.elementor-element-8ab99eb {
    width: 32.5%;
  }
  .elementor-1078 .elementor-element.elementor-element-157fcfe {
    width: 67.5%;
  }
}
@media (max-width: 1024px) {
  .elementor-1078
    .elementor-element.elementor-element-3704794
    .elementor-spacer-inner {
    height: 100px;
  }
  .elementor-1078
    .elementor-element.elementor-element-7c95718
    .elementor-spacer-inner {
    height: 100px;
  }
  .elementor-1078
    .elementor-element.elementor-element-9746134
    .elementor-heading-title {
    font-size: 34px;
  }
  .elementor-1078
    .elementor-element.elementor-element-38a6d06
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
  .elementor-1078
    .elementor-element.elementor-element-b4fa034
    .elementor-spacer-inner {
    height: 59px;
  }
  .elementor-1078
    .elementor-element.elementor-element-e878f75
    .elementor-heading-title {
    font-size: 34px;
  }
  .elementor-1078
    .elementor-element.elementor-element-8fbc28d
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
  .elementor-1078
    .elementor-element.elementor-element-ca370bc
    .elementor-spacer-inner {
    height: 36px;
  }
  .elementor-1078
    .elementor-element.elementor-element-69a3e97
    .elementor-heading-title {
    font-size: 34px;
  }
  .elementor-1078
    .elementor-element.elementor-element-ee05669
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
  .elementor-1078
    .elementor-element.elementor-element-e3428a1
    .elementor-spacer-inner {
    height: 29px;
  }
  .elementor-1078
    .elementor-element.elementor-element-dfdcb05
    .elementor-heading-title {
    font-size: 34px;
  }
  .elementor-1078
    .elementor-element.elementor-element-4b2f7ae
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
  .elementor-1078
    .elementor-element.elementor-element-ed9cbc0
    .elementor-heading-title {
    font-size: 34px;
  }
  .elementor-1078
    .elementor-element.elementor-element-2c8a178
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
  .elementor-1078
    .elementor-element.elementor-element-f8b2737
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
}
@media (max-width: 767px) {
  .elementor-1078
    .elementor-element.elementor-element-3704794
    .elementor-spacer-inner {
    height: 75px;
  }
  .elementor-1078 .elementor-element.elementor-element-d337612 {
    text-align: left;
  }
  .elementor-1078
    .elementor-element.elementor-element-d337612
    .elementor-heading-title {
    font-size: 18px;
  }
  .elementor-1078
    .elementor-element.elementor-element-d658566
    > .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .elementor-1078 .elementor-element.elementor-element-113b542 {
    text-align: left;
  }
  .elementor-1078
    .elementor-element.elementor-element-113b542
    .elementor-heading-title {
    font-size: 21px;
  }
  .elementor-1078
    .elementor-element.elementor-element-113b542
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 14px;
  }
  .elementor-1078
    .elementor-element.elementor-element-7c95718
    .elementor-spacer-inner {
    height: 13px;
  }
  .elementor-1078
    .elementor-element.elementor-element-9b74238
    .elementor-spacer-inner {
    height: 20px;
  }
  .elementor-1078
    .elementor-element.elementor-element-38a6d06
    > .elementor-widget-container {
    padding: 0;
  }
  .elementor-1078
    .elementor-element.elementor-element-c61e3dc
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-1078
    .elementor-element.elementor-element-24d311a
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-1078
    .elementor-element.elementor-element-aaf640e
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-1078
    .elementor-element.elementor-element-b4fa034
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-1078 .elementor-element.elementor-element-e878f75 {
    text-align: center;
  }
  .elementor-1078
    .elementor-element.elementor-element-8fbc28d
    .elementor-divider {
    text-align: center;
  }
  .elementor-1078
    .elementor-element.elementor-element-8fbc28d
    .elementor-divider-separator {
    margin: 0 auto;
  }
  .elementor-1078
    .elementor-element.elementor-element-8fbc28d
    > .elementor-widget-container {
    padding: 0;
  }
  .elementor-1078 .elementor-element.elementor-element-69a3e97 {
    text-align: center;
  }
  .elementor-1078
    .elementor-element.elementor-element-ee05669
    .elementor-divider {
    text-align: center;
  }
  .elementor-1078
    .elementor-element.elementor-element-ee05669
    .elementor-divider-separator {
    margin: 0 auto;
  }
  .elementor-1078
    .elementor-element.elementor-element-ee05669
    > .elementor-widget-container {
    padding: 0;
  }
  .elementor-1078
    .elementor-element.elementor-element-3372e45
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-1078
    .elementor-element.elementor-element-928cea8
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-1078
    .elementor-element.elementor-element-24d84f1
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-1078 .elementor-element.elementor-element-dfdcb05 {
    text-align: center;
  }
  .elementor-1078
    .elementor-element.elementor-element-4b2f7ae
    .elementor-divider {
    text-align: center;
  }
  .elementor-1078
    .elementor-element.elementor-element-4b2f7ae
    .elementor-divider-separator {
    margin: 0 auto;
  }
  .elementor-1078
    .elementor-element.elementor-element-4b2f7ae
    > .elementor-widget-container {
    padding: 0;
  }
  .elementor-1078
    .elementor-element.elementor-element-05d0bbe
    .elementor-spacer-inner {
    height: 50px;
  }
  .elementor-1078
    .elementor-element.elementor-element-2c8a178
    > .elementor-widget-container {
    padding: 0;
  }
  .elementor-1078
    .elementor-element.elementor-element-f8b2737
    > .elementor-widget-container {
    padding: 0;
  }
}
@media (min-width: 1025px) {
  .elementor-1078
    .elementor-element.elementor-element-e711c87:not(.elementor-motion-effects-element-type-background),
  .elementor-1078
    .elementor-element.elementor-element-e711c87
    > .elementor-motion-effects-container
    > .elementor-motion-effects-layer {
    background-attachment: scroll;
  }
}


.icon-image-holder{
    font-size: 18px; font-style: normal; font-weight: 500; background-color: #f3f3f3; color: #545454; text-align: -webkit-center;
}

.fullfill-text{
font-style: normal; color: #000000; background-color: #f3f3f3; text-align: -webkit-center;}

.fullfill-wish{
    font-size: 21px; font-weight: bold;
}

.support-us{
    font-size: 51px; font-style: normal; font-weight: 700; color: rgb(255, 255, 255); font-family: 'Trebuchet MS', sans-serif; text-transform: uppercase;
}