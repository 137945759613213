.site-card-content,
.site-content {
  background-color: #f7f7f7;
}
.site-content:after,
.site-content:before,
.site-footer:after,
.site-footer:before,
.site-header:after,
.site-header:before,
.site-main:after,
.site-main:before {
  content: "";
  display: table;
}
#primary:after,
#secondary:after,
.ast-container:after,
.ast-row:after,
.clear:after,
.sidebar-main:after,
.site-content:after,
.site-footer:after,
.site-header:after,
.site-main:after {
  clear: both;
}
.comment-content .wp-smiley,
.entry-content .wp-smiley,
.page-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}
embed,
iframe,
object {
  max-width: 100%;
}
.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
}
.wp-caption img[class*="wp-image-"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}
.wp-caption-text {
  text-align: center;
}
.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}
.gallery-columns-2 .gallery-item {
  max-width: 50%;
}
.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}
.gallery-columns-4 .gallery-item {
  max-width: 25%;
}
.gallery-columns-5 .gallery-item {
  max-width: 20%;
}
.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}
.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}
.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}
.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}
.gallery-caption {
  display: block;
}
::selection {
  color: #fff;
  background: #0274be;
}
.site-card-content {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.site-card-content:not(.logged-in) {
  position: relative;
}
#page {
  position: relative;
}
a,
a:focus {
  text-decoration: none;
}
.secondary a *,
.site-footer a *,
.site-header a *,
a {
  transition: all 0.2s linear;
}
.capitalize {
  text-transform: uppercase;
}
img {
  vertical-align: middle;
}
.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
  margin-bottom: 20px;
}
p {
  margin-bottom: 1.75em;
}
blockquote {
  margin: 1.5em 1em 1.5em 3em;
  padding: 1.2em;
  font-size: 1.1em;
  line-height: inherit;
  position: relative;
}
.ast-button,
.button,
input[type="button"],
input[type="submit"] {
  border-radius: 0;
  padding: 18px 30px;
  border: 0;
  box-shadow: none;
  text-shadow: none;
}
.ast-button:hover,
.button:hover,
input[type="button"]:hover,
input[type="submit"]:hover {
  box-shadow: none;
}
.ast-button:active,
.ast-button:focus,
.button:active,
.button:focus,
input[type="button"]:active,
input[type="button"]:focus,
input[type="submit"]:active,
input[type="submit"]:focus {
  box-shadow: none;
}
.site-title {
  font-weight: 400;
}
.site-description,
.site-title {
  margin-bottom: 0;
}
.site-description a,
.site-description:focus a,
.site-description:hover a,
.site-title a,
.site-title:focus a,
.site-title:hover a {
  transition: all 0.2s linear;
}
.site-title a,
.site-title a:focus,
.site-title a:hover,
.site-title a:visited {
  color: #222;
}
.site-description a,
.site-description a:focus,
.site-description a:hover,
.site-description a:visited {
  color: #999;
}
.search-form .search-field {
  outline: 0;
}
.ast-search-menu-icon {
  position: relative;
}
.ast-header-break-point.ast-header-custom-item-outside
  .main-header-bar
  .ast-search-icon {
  margin-right: 1em;
}
.ast-header-break-point.ast-header-custom-item-inside
  .main-header-bar
  .main-header-bar-navigation
  .ast-search-icon {
  display: none;
}
.ast-header-break-point.ast-header-custom-item-inside
  .main-header-bar
  .ast-search-menu-icon
  .search-field,
.ast-header-break-point.ast-header-custom-item-inside
  .main-header-bar
  .ast-search-menu-icon.ast-inline-search
  .search-field {
  width: 100%;
  padding-right: 5.5em;
}
.ast-header-break-point.ast-header-custom-item-inside
  .main-header-bar
  .ast-search-menu-icon
  .search-submit {
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  padding: 0 1em;
  border-radius: 0;
}
.ast-header-break-point.ast-header-custom-item-inside
  .main-header-bar
  .ast-search-menu-icon
  .search-form {
  padding: 0;
  display: block;
  overflow: hidden;
}
.site .skip-link {
  background-color: #f1f1f1;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  color: #21759b;
  display: block;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 700;
  left: -9999em;
  outline: 0;
  padding: 15px 23px 14px;
  text-decoration: none;
  text-transform: none;
  top: -9999em;
}
.site .skip-link:focus {
  clip: auto;
  height: auto;
  left: 6px;
  top: 7px;
  width: auto;
  z-index: 100000;
}
.logged-in .site .skip-link {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  font-family: "Open Sans", sans-serif;
}
.astra-icon-down_arrow::after {
  content: "\e900";
  font-family: Astra;
}
.astra-icon-close::after {
  content: "\e5cd";
  font-family: Astra;
}
.astra-icon-drag_handle::after {
  content: "\e25d";
  font-family: Astra;
}
.astra-icon-format_align_justify::after {
  content: "\e235";
  font-family: Astra;
}
.astra-icon-menu::after {
  content: "\e5d2";
  font-family: Astra;
}
.astra-icon-reorder::after {
  content: "\e8fe";
  font-family: Astra;
}
.astra-icon-search::after {
  content: "\e8b6";
  font-family: Astra;
}
.astra-icon-zoom_in::after {
  content: "\e56b";
  font-family: Astra;
}
.astra-icon-check-circle::after {
  content: "\e901";
  font-family: Astra;
}
.astra-icon-shopping-cart::after {
  content: "\f07a";
  font-family: Astra;
}
.astra-icon-shopping-bag::after {
  content: "\f290";
  font-family: Astra;
}
.astra-icon-shopping-basket::after {
  content: "\f291";
  font-family: Astra;
}
.astra-icon-circle-o::after {
  content: "\e903";
  font-family: Astra;
}
.astra-icon-certificate::after {
  content: "\e902";
  font-family: Astra;
}
input,
select {
  line-height: 1;
}
.site-card-content,
button,
input[type="button"],
input[type="submit"],
textarea {
  line-height: 1.85714285714286;
}
.site-title a {
  line-height: 1.2;
}
.site-header .site-description {
  line-height: 1.5;
}
.ast-single-post .entry-title,
.ast-single-post .entry-title a {
  line-height: 1.2;
}
.entry-title,
.entry-title a {
  font-weight: 400;
}
.ast-social-icons {
  list-style-type: none;
  margin: 0;
}
.ast-social-icons li {
  display: inline-block;
  padding-right: 15px;
}
.ast-social-icons li:last-child {
  padding-right: 0;
}
.ast-social-icons a {
  display: block;
}
.ast-social-icons i {
  font-style: normal;
  font-family: fontawesome;
  font-weight: 400;
}
.ast-social-icons i:before {
  font-weight: 400;
}
.single .entry-content .gallery {
  margin-left: -10px;
  margin-right: -10px;
}
.gallery-caption {
  color: #000;
  padding: 10px;
  border-radius: 0 0 2px 2px;
  border: 1px solid #eaeaea;
  border-top: none;
  font-size: 0.9em;
}
.gallery-icon {
  border-bottom: 0;
  border-radius: 2px 2px 0 0;
  border: 1px solid #eaeaea;
  overflow: hidden;
}
.gallery-item {
  margin: 0;
  padding: 10px;
  border-radius: 2px;
}
.gallery-item:last-child {
  margin-bottom: 2em;
}
.elementor-image-gallery .gallery-icon {
  border: none;
}
.ast-oembed-container {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}
.ast-oembed-container embed,
.ast-oembed-container iframe,
.ast-oembed-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.site-card-content .ast-oembed-container * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hidden {
  display: none !important;
}
.site-card-content {
  background-color: #fff;
}
#page {
  display: block;
}
#primary,
#secondary {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}
#primary {
  margin: 4em 0;
}
#secondary {
  margin: 4em 0 2.5em;
  word-break: break-word;
  line-height: 2;
}
@media (max-width: 768px) {
  #secondary.secondary {
    padding-top: 0;
  }
}
#secondary li {
  margin-bottom: 0.25em;
}
#secondary li:last-child {
  margin-bottom: 0;
}
.site-card-content {
  background-color: #f5f5f5;
}
.site-card-content #primary {
  padding: 4em 0;
  margin: 0;
  border: 0;
}
.site-card-content .site-main > .ast-row {
  margin-left: 0;
  margin-right: 0;
}
.site-card-content .ast-article-post,
.site-card-content .ast-article-single {
  background-color: #fff !important;
  border-bottom: 1px solid #eee;
  margin: 0;
  padding: 5.34em 6.67em;
}
.site-card-content .blog-layout-1 {
  padding: 0;
  border-bottom: 0;
}
.site-card-content .ast-article-single {
  border-bottom: 0;
}
@media (max-width: 1200px) {
  .site-card-content .ast-article-post,
  .site-card-content .ast-article-single {
    margin: 0;
    padding: 3.34em 2.4em;
  }
}
@media (max-width: 768px) {
  .site-card-content .ast-article-post,
  .site-card-content .ast-article-single {
    padding: 1.5em 2.14em;
  }
  .site-card-content #primary,
  .site-card-content #secondary {
    padding: 1.5em 0;
  }
  .site-card-content.ast-right-sidebar #secondary {
    padding-left: 1em;
  }
  .site-card-content.ast-right-sidebar #secondary {
    padding-right: 1em;
  }
  .site-card-content.ast-two-container #secondary {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 544px) {
  .site-card-content .ast-article-post,
  .site-card-content .ast-article-single {
    padding: 1.5em 1em;
  }
  .site-card-content #content .ast-container {
    padding-left: 0.54em;
    padding-right: 0.54em;
  }
  .site-card-content #secondary {
    padding-top: 0;
  }
}
.ast-page-builder-template .hentry {
  margin: 0;
}
.ast-page-builder-template .site-content > .ast-container {
  max-width: 100%;
  padding: 0;
}
.ast-page-builder-template .site-content #primary {
  padding: 0;
  margin: 0;
}
.ast-page-builder-template .no-results {
  text-align: center;
  margin: 4em auto;
}
.ast-page-builder-template .ast-pagination {
  padding: 2em;
}
.ast-page-builder-template .entry-header {
  margin-top: 0;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: auto;
}
.ast-page-builder-template .entry-header.ast-no-title.ast-no-thumbnail {
  margin-top: 0;
}
.ast-page-builder-template .entry-header.ast-header-without-markup {
  margin-top: 0;
  margin-bottom: 0;
}
.ast-page-builder-template .entry-header.ast-no-title.ast-no-meta {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .ast-page-builder-template .entry-header {
    margin-top: 1.5em;
  }
}
@media (max-width: 768px) {
  .ast-page-builder-template #secondary {
    margin-top: 1.5em;
  }
}
.ast-page-builder-template.ast-left-sidebar #secondary {
  padding-left: 20px;
}
@media (max-width: 768px) {
  .ast-page-builder-template.ast-left-sidebar #secondary {
    padding-right: 20px;
  }
}
.ast-page-builder-template.ast-right-sidebar #secondary {
  padding-right: 20px;
}
@media (max-width: 768px) {
  .ast-page-builder-template.ast-right-sidebar #secondary {
    padding-left: 20px;
  }
}
.ast-page-builder-template.single .post-navigation {
  padding-bottom: 2em;
}
.ast-page-builder-template .ast-archive-description {
  margin-top: 4em;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.ast-pagination .nav-links {
  display: inline-block;
  width: 100%;
}
@media (max-width: 420px) {
  .ast-pagination .next.page-numbers,
  .ast-pagination .prev.page-numbers {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}
.ast-pagination .next.page-numbers,
.ast-pagination .next.page-numbers:focus,
.ast-pagination .next.page-numbers:visited,
.ast-pagination .prev.page-numbers,
.ast-pagination .prev.page-numbers:focus,
.ast-pagination .prev.page-numbers:visited {
  display: inline-block;
  width: auto;
}
@media (min-width: 769px) {
  .ast-pagination .next.page-numbers.next,
  .ast-pagination .next.page-numbers:focus.next,
  .ast-pagination .next.page-numbers:visited.next,
  .ast-pagination .prev.page-numbers.next,
  .ast-pagination .prev.page-numbers:focus.next,
  .ast-pagination .prev.page-numbers:visited.next {
    margin-right: 0;
  }
}
.nav-links {
  -js-display: flex;
  display: flex;
}
.single .post-navigation .nav-next,
.single .post-navigation .nav-previous {
  flex: auto;
}
@media (max-width: 420px) {
  .single .post-navigation .ast-left-arrow,
  .single .post-navigation .ast-right-arrow {
    display: none;
  }
  .single .post-navigation .nav-next,
  .single .post-navigation .nav-previous {
    width: 100%;
  }
  .single .post-navigation .nav-next a,
  .single .post-navigation .nav-previous a {
    width: 100%;
  }
  .single .post-navigation .nav-previous {
    margin-bottom: 1em;
  }
}
.single.ast-page-builder-template .entry-header {
  padding-left: 20px;
  padding-right: 20px;
}
.single .entry-header .ast-single-post-order + .post-thumb img {
  margin-top: 2em;
  margin-bottom: 0;
}
.single .entry-header.ast-no-title .post-thumb + .ast-single-post-order {
  margin-top: 0;
}
.single .entry-header .post-thumb + .ast-single-post-order {
  margin-top: 2em;
}
.single .entry-header .post-thumb img {
  margin-top: 0;
  margin-bottom: 0;
}
.page .has-post-thumbnail .post-thumb img {
  margin-bottom: 1.5em;
}
.post-password-form {
  text-align: center;
}
@media (max-width: 420px) {
  .post-password-form input[type="password"] {
    display: block;
    margin: 10px auto;
  }
}
.post-password-form input[type="submit"] {
  padding: 10px 20px;
  border-radius: 2px;
}
.site-card-content .post-navigation {
  border-top: 0;
  padding-left: 3.33333em;
  padding-top: 3.33333em;
  padding-right: 3.33333em;
}
@media (max-width: 420px) {
  .site-card-content .post-navigation {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  .site-card-content .entry-header {
    margin-bottom: 1em;
  }
}
.ast-page-builder-template.single-post .site-content > .ast-container {
  max-width: 100%;
}
.comments-count-wrapper {
  padding: 2em 0;
}
.comments-count-wrapper .comments-title {
  font-weight: 400;
  word-wrap: break-word;
}
.ast-comment-list {
  margin: 0;
  word-wrap: break-word;
  padding-bottom: 0.5em;
  list-style: none;
}
.ast-comment-list li {
  list-style: none;
}
.ast-comment-list li.depth-1 .ast-comment,
.ast-comment-list li.depth-2 .ast-comment {
  border-bottom: 1px solid #eee;
}
.ast-comment-list .children {
  margin-left: 2em;
}
@media (max-width: 992px) {
  .ast-comment-list .children {
    margin-left: 1em;
  }
}
@media (max-width: 544px) {
  .ast-comment-list .children {
    margin-left: 0.66666em;
  }
}
.ast-comment-list .comment-respond {
  padding: 1em 0;
  border-bottom: 1px solid #eee;
}
.ast-comment-list .comment-respond .comment-reply-title {
  margin-top: 0;
  padding-top: 0;
}
.ast-comment-list .comment-respond p {
  margin-bottom: 0.5em;
}
.ast-comment-list #cancel-comment-reply-link {
  white-space: nowrap;
  font-size: 15px;
  font-size: 1rem;
  margin-left: 1em;
}
.ast-comment-list .ast-comment-edit-reply-wrap {
  -js-display: flex;
  display: flex;
  justify-content: flex-end;
}
.ast-comment-list .ast-edit-link {
  flex: 1;
}
.ast-comment-list .comment-awaiting-moderation {
  margin-bottom: 0;
}
.ast-comment {
  padding: 1em 0;
}
.ast-comment-avatar-wrap {
  float: left;
  clear: right;
  margin-right: 1.33333em;
}
@media (max-width: 768px) {
  .ast-comment-avatar-wrap {
    margin-right: 0.5em;
  }
}
.ast-comment-avatar-wrap img {
  border-radius: 50%;
}
@media (max-width: 768px) {
  .ast-comment-avatar-wrap img {
    max-width: 2.5em;
  }
}
.ast-comment-meta-wrap {
  float: left;
  clear: right;
  padding: 0 0 1.33333em;
}
.ast-comment-content {
  clear: both;
}
.ast-comment-cite-wrap {
  text-align: left;
}
.ast-comment-cite-wrap cite {
  font-style: normal;
}
.comment-reply-title {
  padding-top: 1em;
  font-weight: 400;
  line-height: 1.65;
}
.ast-comment-meta {
  margin-bottom: 0.5em;
}
.ast-comment-time .reply,
.ast-comment-time .timendate {
  margin-right: 0.5em;
}
.comments-area {
  border-top: 1px solid #eee;
  margin-top: 2em;
}
@media (max-width: 768px) {
  .comments-area {
    margin-top: 1.5em;
  }
}
.site-card-content .post-navigation {
  border-top: 0;
  padding-left: 3.33333em;
  padding-right: 3.33333em;
}
@media (max-width: 420px) {
  .site-card-content .post-navigation {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  .site-card-content .entry-header {
    margin-bottom: 1em;
  }
}
.ast-page-builder-template.single-post .site-content > .ast-container {
  max-width: 100%;
}
.comments-count-wrapper {
  padding: 2em 0;
}
.comments-count-wrapper .comments-title {
  font-weight: 400;
  word-wrap: break-word;
}
.ast-comment-list {
  margin: 0;
  word-wrap: break-word;
  padding-bottom: 0.5em;
  list-style: none;
}
.ast-comment-list li {
  list-style: none;
}
.ast-comment-list li.depth-1 .ast-comment,
.ast-comment-list li.depth-2 .ast-comment {
  border-bottom: 1px solid #eee;
}
.ast-comment-list .children {
  margin-left: 2em;
}
@media (max-width: 992px) {
  .ast-comment-list .children {
    margin-left: 1em;
  }
}
@media (max-width: 544px) {
  .ast-comment-list .children {
    margin-left: 0.66666em;
  }
}

/* internal */
.link-internal {
  box-sizing: inherit;
  border-radius: 2px;
  background-color: rgba(0, 127, 172, 0.2);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  white-space: pre-wrap;
}

.konica {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  white-space: pre-wrap;
  background-color: #f7f7f7;
}

.new-normal {
  color: rgb(0, 0, 0);
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  background-color: rgb(247, 247, 247);
}
.what-is {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  white-space: pre-wrap;
  background-color: rgb(247, 247, 247);
}

.new-norm {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  white-space: pre-wrap;
  background-color: #f7f7f7;
}

.knowledge {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  white-space: pre-wrap;
  background-color: rgb(247, 247, 247);
}
.dashe {
  font-weight: normal;
  font-size: 12pt;
  font-family: Arial;
  color: #0e101a;
  font-weight: 400;
  font-style: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  white-space: pre-wrap;
}

.school-supp {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  white-space: pre-wrap;
  background-color: rgb(247, 247, 247);
}

.aug {
  color: rgb(0, 0, 0);
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-weight: 400;
  letter-spacing: normal;
  orphans: 2;
  text-align: start;
  text-indent: 0px;
  text-transform: none;
  white-space: pre-wrap;
  widows: 2;
  word-spacing: 0px;
  -webkit-text-stroke-width: 0px;
  background-color: rgb(247, 247, 247);
  text-decoration-style: initial;
  text-decoration-color: initial;
  display: inline !important;
  float: none;
}

.support {
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  white-space: pre-wrap;
  background-color: #f7f7f7;
}

.sponsor-child {
  box-sizing: inherit;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  white-space: pre-wrap;
}
.brkline {
  box-sizing: inherit;
  white-space: pre-wrap;
  background-color: #f7f7f7;
}

.sponsor-school {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  white-space: pre-wrap;
}

.mapya {
  box-sizing: inherit;
  border-radius: 2px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  white-space: pre-wrap;
}

.elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1200px;
}
.elementor-widget:not(:last-child) {
  margin-bottom: 10px;
}

.elementor-1779
  .elementor-element.elementor-element-e664427
  > .elementor-widget-container {
  margin: 0 0 -18px;
}
.elementor-1779
  .elementor-element.elementor-element-be12e20
  .elementor-image
  img {
  width: 97%;
  max-width: 100%;
}
.elementor-1779
  .elementor-element.elementor-element-ee370fb
  > .elementor-widget-container {
  margin: 23px 0 -21px;
}
.elementor-1779
  .elementor-element.elementor-element-cec4b6e
  .elementor-icon-list-icon
  i {
  font-size: 14px;
}
.elementor-1779
  .elementor-element.elementor-element-cec4b6e
  .elementor-icon-list-icon
  svg {
  width: 14px;
}
.elementor-1779
  .elementor-element.elementor-element-680a69e
  > .elementor-widget-container {
  margin: 0 0 -19px;
}
.elementor-1779
  .elementor-element.elementor-element-e9b8917
  .elementor-icon-list-icon
  i {
  font-size: 14px;
}
.elementor-1779
  .elementor-element.elementor-element-e9b8917
  .elementor-icon-list-icon
  svg {
  width: 14px;
}
.elementor-1779
  .elementor-element.elementor-element-8fc6f4b
  > .elementor-widget-container {
  margin: 0 0 -23px;
}
.elementor-1779
  .elementor-element.elementor-element-eddf385
  .elementor-icon-list-icon
  i {
  font-size: 14px;
}
.elementor-1779
  .elementor-element.elementor-element-eddf385
  .elementor-icon-list-icon
  svg {
  width: 14px;
}
.elementor-1779
  .elementor-element.elementor-element-3416e0a
  .elementor-image
  img {
  width: 100%;
  max-width: 96%;
}
.elementor-1779
  .elementor-element.elementor-element-4283092
  .elementor-image
  img {
  width: 100%;
  max-width: 100%;
}
.elementor-1779 .elementor-element.elementor-element-3efb0ec {
  color: #000;
}
.elementor-1779
  .elementor-element.elementor-element-3efb0ec
  > .elementor-widget-container {
  margin: 19px 0 -27px;
}
.elementor-1779 .elementor-element.elementor-element-a3b8d10 {
  color: #000;
}
.elementor-1779 .elementor-element.elementor-element-cf96c79 {
  color: #000;
}
.elementor-1779
  .elementor-element.elementor-element-cf96c79
  > .elementor-widget-container {
  margin: -2px 0 -27px;
}


.font-15{
  font-size: 15px;
}

.link-white{
  font-size: 15px; background-color: #ffffff;
}

.elementor-1715 .elementor-element.elementor-element-22f624f {
  text-align: center;
}


.elementor-1715 .elementor-element.elementor-element-ecec156 .elementor-spacer-inner {
  height: 50px;
}

.elementor-1715 .elementor-element.elementor-element-22f624f .elementor-heading-title {
  font-size: 54px;
}