.elementor-12
  .elementor-element.elementor-element-8a0de87:not(.elementor-motion-effects-element-type-background),
.elementor-12
  .elementor-element.elementor-element-8a0de87
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../assets/images/20190525_115642-scaled-1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.elementor-12
  .elementor-element.elementor-element-8a0de87
  > .elementor-background-overlay {
  background-color: rgba(0, 0, 0, 0.57);
  opacity: 0.8;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-12 .elementor-element.elementor-element-8a0de87 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-12
  .elementor-element.elementor-element-990ae94
  .elementor-spacer-inner {
  height: 214px;
}
.elementor-12 .elementor-element.elementor-element-0bdf123 {
  text-align: left;
}
.elementor-12
  .elementor-element.elementor-element-0bdf123
  .elementor-heading-title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}
.elementor-12
  .elementor-element.elementor-element-0bdf123
  > .elementor-widget-container {
  margin: 0 0 -21px;
}
.elementor-12 .elementor-element.elementor-element-4e0afc4 {
  --divider-border-style: solid;
  --divider-border-color: #f2b22e;
  --divider-border-width: 5px;
}
.elementor-12
  .elementor-element.elementor-element-4e0afc4
  .elementor-divider-separator {
  width: 100px;
  margin: 0 auto;
  margin-left: 0;
}
.elementor-12 .elementor-element.elementor-element-4e0afc4 .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.elementor-12
  .elementor-element.elementor-element-4e0afc4
  > .elementor-widget-container {
  padding: 0 0 20px;
}
.elementor-12 .elementor-element.elementor-element-749c1fd {
  text-align: left;
}
.elementor-12
  .elementor-element.elementor-element-749c1fd
  .elementor-heading-title {
  color: #fff;
  font-size: 51px;
}
.elementor-12
  .elementor-element.elementor-element-749c1fd
  > .elementor-widget-container {
  padding: 0 0 50px;
}
.elementor-12 .elementor-element.elementor-element-33b6602 .elementor-button {
  font-size: 18px;
  font-weight: 700;
  fill: #041793;
  color: #041793;
  background-color: #eab22e;
}
.elementor-12
  .elementor-element.elementor-element-33b6602
  .elementor-button:hover,
.elementor-12
  .elementor-element.elementor-element-33b6602
  .elementor-button:focus {
  color: #fff;
  background-color: #eab22e;
}
.elementor-12
  .elementor-element.elementor-element-33b6602
  .elementor-button:hover
  svg,
.elementor-12
  .elementor-element.elementor-element-33b6602
  .elementor-button:focus
  svg {
  fill: #fff;
}
.elementor-12
  .elementor-element.elementor-element-ce29301
  .elementor-spacer-inner {
  height: 150px;
}
.elementor-12
  .elementor-element.elementor-element-e0efb15
  > .elementor-container
  > .elementor-row
  > .elementor-column
  > .elementor-column-wrap
  > .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.elementor-12
  .elementor-element.elementor-element-e0efb15:not(.elementor-motion-effects-element-type-background),
.elementor-12
  .elementor-element.elementor-element-e0efb15
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #eaeaea;
}
.elementor-12
  .elementor-element.elementor-element-e0efb15
  > .elementor-background-overlay {
  opacity: 0.8;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-12
  .elementor-element.elementor-element-e0efb15
  .elementor-background-overlay {
  filter: brightness(100%) contrast(100%) saturate(100%) blur(0px)
    hue-rotate(0deg);
}
.elementor-12 .elementor-element.elementor-element-e0efb15 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-12
  .elementor-element.elementor-element-ee42ebe
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-12 .elementor-element.elementor-element-96c594b {
  text-align: center;
}
.elementor-12
  .elementor-element.elementor-element-96c594b
  .elementor-heading-title {
  color: #000;
}
.elementor-12
  .elementor-element.elementor-element-96c594b
  > .elementor-widget-container {
  margin: 0 0 -26px;
}
.elementor-12 .elementor-element.elementor-element-93cd944 {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-12
  .elementor-element.elementor-element-93cd944
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
}
.elementor-12 .elementor-element.elementor-element-93cd944 .elementor-divider {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-12
  .elementor-element.elementor-element-073b1e1
  > .elementor-container
  > .elementor-row
  > .elementor-column
  > .elementor-column-wrap
  > .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.elementor-12
  .elementor-element.elementor-element-e39c955
  > .elementor-element-populated {
  margin: 0 50px 0 0;
}
.elementor-12
  .elementor-element.elementor-element-3f2e41b
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-12 .elementor-element.elementor-element-809c6fd {
  color: #333;
}
.elementor-12 .elementor-element.elementor-element-4aa8fbc {
  color: #333;
}
.elementor-12 .elementor-element.elementor-element-6ad0610 .elementor-button {
  font-size: 18px;
  font-weight: 700;
  fill: #041793;
  color: #041793;
  background-color: #eab22e;
  padding: 25px 60px;
}
.elementor-12
  .elementor-element.elementor-element-6ad0610
  .elementor-button:hover,
.elementor-12
  .elementor-element.elementor-element-6ad0610
  .elementor-button:focus {
  color: #fff;
}
.elementor-12
  .elementor-element.elementor-element-6ad0610
  .elementor-button:hover
  svg,
.elementor-12
  .elementor-element.elementor-element-6ad0610
  .elementor-button:focus
  svg {
  fill: #fff;
}
.elementor-12 .elementor-element.elementor-element-6ad0610 {
  width: 100%;
  max-width: 100%;
}
.elementor-12
  .elementor-element.elementor-element-5679d39
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-12
  .elementor-element.elementor-element-2775c09:not(.elementor-motion-effects-element-type-background)
  > .elementor-column-wrap,
.elementor-12
  .elementor-element.elementor-element-2775c09
  > .elementor-column-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../assets/images/IMG_20190525_110026.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.elementor-12
  .elementor-element.elementor-element-2775c09
  > .elementor-element-populated
  > .elementor-background-overlay {
  opacity: 0.5;
}
.elementor-12
  .elementor-element.elementor-element-2775c09
  > .elementor-element-populated,
.elementor-12
  .elementor-element.elementor-element-2775c09
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-12
  .elementor-element.elementor-element-2775c09
  > .elementor-background-slideshow {
  border-radius: 42px;
}
.elementor-12
  .elementor-element.elementor-element-2775c09
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0 15px 0 0;
  padding: 0;
}
.elementor-12
  .elementor-element.elementor-element-2775c09
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-12
  .elementor-element.elementor-element-e9b6963
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-12
  .elementor-element.elementor-element-f6ae640
  .elementor-spacer-inner {
  height: 40px;
}
.elementor-12
  .elementor-element.elementor-element-4db683b
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-12
  .elementor-element.elementor-element-4c84c04
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-12 .elementor-element.elementor-element-794d25c {
  text-align: center;
}
.elementor-12
  .elementor-element.elementor-element-794d25c
  > .elementor-widget-container {
  margin: 0 0 -27px;
}
.elementor-12 .elementor-element.elementor-element-04147ef {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-12
  .elementor-element.elementor-element-04147ef
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
}
.elementor-12 .elementor-element.elementor-element-04147ef .elementor-divider {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-12
  .elementor-element.elementor-element-6db32f7
  > .elementor-widget-container {
  padding: 20px 20px 0;
}
.elementor-12
  .elementor-element.elementor-element-5d91b33
  .elementor-heading-title {
  font-size: 22px;
}
.elementor-12
  .elementor-element.elementor-element-d227613
  .elementor-heading-title {
  font-size: 22px;
}
.elementor-12 .elementor-element.elementor-element-bae3443 {
  color: #7a7a7a;
}
.elementor-12
  .elementor-element.elementor-element-a4af25b
  .elementor-heading-title {
  font-size: 22px;
}
.elementor-12
  .elementor-element.elementor-element-65b1a0a
  .elementor-heading-title {
  font-size: 22px;
}
.elementor-12 .elementor-element.elementor-element-d2165e2 {
  color: #7a7a7a;
}
.elementor-12
  .elementor-element.elementor-element-5f1fcb7
  .elementor-heading-title {
  font-size: 22px;
}
.elementor-12
  .elementor-element.elementor-element-a4e9278
  .elementor-heading-title {
  font-size: 22px;
}
.elementor-12 .elementor-element.elementor-element-98f80ec {
  color: #7a7a7a;
}
.elementor-12
  .elementor-element.elementor-element-43bbb91
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-12
  .elementor-element.elementor-element-a0668b2:not(.elementor-motion-effects-element-type-background),
.elementor-12
  .elementor-element.elementor-element-a0668b2
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../assets/images/group-scaled-1.jpg");
  background-position: center center;
  background-size: cover;
}
.elementor-12 .elementor-element.elementor-element-a0668b2 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-12
  .elementor-element.elementor-element-a0668b2
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-12
  .elementor-element.elementor-element-2c5d2e1
  .elementor-spacer-inner {
  height: 20px;
}
.elementor-12
  .elementor-element.elementor-element-a82b062
  .elementor-text-editor {
  text-align: center;
}
.elementor-12 .elementor-element.elementor-element-a82b062 {
  color: #fff;
  font-size: 25px;
  font-weight: 400;
  line-height: 1.2em;
}
.elementor-12 .elementor-element.elementor-element-94cf576 {
  text-align: center;
}
.elementor-12
  .elementor-element.elementor-element-94cf576
  .elementor-heading-title {
  color: #fff;
}
.elementor-12
  .elementor-element.elementor-element-94cf576
  > .elementor-widget-container {
  margin: 0 0 -25px;
}
.elementor-12 .elementor-element.elementor-element-8fa1666 {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-12
  .elementor-element.elementor-element-8fa1666
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
}
.elementor-12 .elementor-element.elementor-element-8fa1666 .elementor-divider {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-12
  .elementor-element.elementor-element-cfde0bc
  .elementor-text-editor {
  text-align: center;
}
.elementor-12 .elementor-element.elementor-element-cfde0bc {
  color: #fff;
}
.elementor-12
  .elementor-element.elementor-element-47d220a:not(.elementor-motion-effects-element-type-background)
  > .elementor-column-wrap,
.elementor-12
  .elementor-element.elementor-element-47d220a
  > .elementor-column-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #fcfcfc;
}
.elementor-12
  .elementor-element.elementor-element-47d220a
  > .elementor-element-populated,
.elementor-12
  .elementor-element.elementor-element-47d220a
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-12
  .elementor-element.elementor-element-47d220a
  > .elementor-background-slideshow {
  border-radius: 18px;
}
.elementor-12
  .elementor-element.elementor-element-47d220a
  > .elementor-element-populated {
  box-shadow: 7px 4px 18px 0px rgba(0, 0, 0, 0.5);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0 60px;
  padding: 48px 0 12px;
}
.elementor-12
  .elementor-element.elementor-element-47d220a
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-12 .elementor-element.elementor-element-282843e {
  text-align: center;
}
.elementor-12
  .elementor-element.elementor-element-282843e
  .elementor-heading-title {
  color: #eab22e;
}
.elementor-12
  .elementor-element.elementor-element-282843e
  > .elementor-widget-container {
  margin: 1px 1px -19px;
  padding: 0;
}
.elementor-12
  .elementor-element.elementor-element-cee4365
  .elementor-text-editor {
  text-align: center;
}
.elementor-12 .elementor-element.elementor-element-cee4365 {
  color: #4b3e3e;
  font-size: 25px;
  font-weight: 700;
}
.elementor-12
  .elementor-element.elementor-element-d24e70e:not(.elementor-motion-effects-element-type-background)
  > .elementor-column-wrap,
.elementor-12
  .elementor-element.elementor-element-d24e70e
  > .elementor-column-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #fff;
}
.elementor-12
  .elementor-element.elementor-element-d24e70e
  > .elementor-element-populated,
.elementor-12
  .elementor-element.elementor-element-d24e70e
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-12
  .elementor-element.elementor-element-d24e70e
  > .elementor-background-slideshow {
  border-radius: 18px;
}
.elementor-12
  .elementor-element.elementor-element-d24e70e
  > .elementor-element-populated {
  box-shadow: 7px 4px 18px 0px rgba(0, 0, 0, 0.5);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0 60px;
  padding: 48px 0 12px;
}
.elementor-12
  .elementor-element.elementor-element-d24e70e
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-12 .elementor-element.elementor-element-2c6151d {
  text-align: center;
}
.elementor-12
  .elementor-element.elementor-element-2c6151d
  .elementor-heading-title {
  color: #eab22e;
}
.elementor-12
  .elementor-element.elementor-element-2c6151d
  > .elementor-widget-container {
  margin: 1px 1px -19px;
  padding: 0;
}
.elementor-12
  .elementor-element.elementor-element-4a59c36
  .elementor-text-editor {
  text-align: center;
}
.elementor-12 .elementor-element.elementor-element-4a59c36 {
  color: #4b3e3e;
  font-size: 25px;
  font-weight: 700;
}
.elementor-12
  .elementor-element.elementor-element-fca0b31:not(.elementor-motion-effects-element-type-background)
  > .elementor-column-wrap,
.elementor-12
  .elementor-element.elementor-element-fca0b31
  > .elementor-column-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #fff;
}
.elementor-12
  .elementor-element.elementor-element-fca0b31
  > .elementor-element-populated,
.elementor-12
  .elementor-element.elementor-element-fca0b31
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-12
  .elementor-element.elementor-element-fca0b31
  > .elementor-background-slideshow {
  border-radius: 18px;
}
.elementor-12
  .elementor-element.elementor-element-fca0b31
  > .elementor-element-populated {
  box-shadow: 7px 4px 18px 0px rgba(0, 0, 0, 0.5);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0 60px;
  padding: 48px 0 12px;
}
.elementor-12
  .elementor-element.elementor-element-fca0b31
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-12 .elementor-element.elementor-element-97e3ed5 {
  text-align: center;
}
.elementor-12
  .elementor-element.elementor-element-97e3ed5
  .elementor-heading-title {
  color: #eab22e;
}
.elementor-12
  .elementor-element.elementor-element-97e3ed5
  > .elementor-widget-container {
  margin: 1px 1px -19px;
  padding: 0;
}
.elementor-12
  .elementor-element.elementor-element-2ac5d08
  .elementor-text-editor {
  text-align: center;
}
.elementor-12 .elementor-element.elementor-element-2ac5d08 {
  color: #4b3e3e;
  font-size: 25px;
  font-weight: 700;
}
.elementor-12
  .elementor-element.elementor-element-afd5a20
  .elementor-spacer-inner {
  height: 104px;
}
.elementor-12
  .elementor-element.elementor-element-7b90a1f:not(.elementor-motion-effects-element-type-background),
.elementor-12
  .elementor-element.elementor-element-7b90a1f
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f4f4f4;
}
.elementor-12 .elementor-element.elementor-element-7b90a1f {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-12
  .elementor-element.elementor-element-7b90a1f
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-12
  .elementor-element.elementor-element-0bbc0e4
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-12
  .elementor-element.elementor-element-e5c05aa
  .elementor-text-editor {
  text-align: center;
}
.elementor-12
  .elementor-element.elementor-element-f62b4d0
  > .elementor-element-populated {
  margin: 0 15px;
}
.elementor-12 .elementor-element.elementor-element-c538c88 {
  color: #717171;
}
.elementor-12
  .elementor-element.elementor-element-c538c88
  > .elementor-widget-container {
  padding: 0 35px 5px;
  transition: background 0.4s;
  border-style: solid;
  border-width: 0 0 6px;
  border-color: #feca2b;
  border-radius: 20px;
  box-shadow: 5px 7px 29px 0px rgba(0, 0, 0, 0.5);
}
.elementor-12
  .elementor-element.elementor-element-c538c88:hover
  .elementor-widget-container {
  background-color: #5d5d5d;
}
.elementor-12
  .elementor-element.elementor-element-b7623b9
  > .elementor-element-populated {
  margin: 0 15px;
}
.elementor-12 .elementor-element.elementor-element-df48c5b {
  color: #717171;
}
.elementor-12
  .elementor-element.elementor-element-df48c5b
  > .elementor-widget-container {
  padding: 0 35px 32px;
  border-style: solid;
  border-width: 0 0 6px;
  border-color: #feca2b;
  border-radius: 20px;
  box-shadow: 5px 7px 29px 0px rgba(0, 0, 0, 0.5);
}
.elementor-12
  .elementor-element.elementor-element-df48c5b:hover
  .elementor-widget-container {
  background-color: #5d5d5d;
}
.elementor-12
  .elementor-element.elementor-element-3f39e85
  > .elementor-element-populated {
  margin: 0 15px;
}
.elementor-12 .elementor-element.elementor-element-ef55c26 {
  color: #717171;
}
.elementor-12
  .elementor-element.elementor-element-ef55c26
  > .elementor-widget-container {
  padding: 0 35px 32px;
  border-style: solid;
  border-width: 0 0 6px;
  border-color: #feca2b;
  border-radius: 20px;
  box-shadow: 5px 7px 29px 0px rgba(0, 0, 0, 0.5);
}
.elementor-12
  .elementor-element.elementor-element-ef55c26:hover
  .elementor-widget-container {
  background-color: #5d5d5d;
}
.elementor-12
  .elementor-element.elementor-element-e8a38b7
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-12
  .elementor-element.elementor-element-53fcf80
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-12 .elementor-element.elementor-element-288c6a4 {
  text-align: center;
}
.elementor-12
  .elementor-element.elementor-element-288c6a4
  > .elementor-widget-container {
  margin: 0 0 -24px;
}
.elementor-12 .elementor-element.elementor-element-d1957ce {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-12
  .elementor-element.elementor-element-d1957ce
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
}
.elementor-12 .elementor-element.elementor-element-d1957ce .elementor-divider {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-12
  .elementor-element.elementor-element-5e565cd
  .elementor-spacer-inner {
  height: 18px;
}
.elementor-12
  .elementor-element.elementor-element-b2c9164
  .elementor-swiper-button.elementor-swiper-button-prev,
.elementor-12
  .elementor-element.elementor-element-b2c9164
  .elementor-swiper-button.elementor-swiper-button-next {
  font-size: 38px;
  color: #eab22e;
}
.elementor-12
  .elementor-element.elementor-element-b2c9164
  > .elementor-widget-container {
  margin: 9px;
}
.elementor-12
  .elementor-element.elementor-element-16ee1dd
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-12
  .elementor-element.elementor-element-10cb21c:not(.elementor-motion-effects-element-type-background),
.elementor-12
  .elementor-element.elementor-element-10cb21c
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f4f4f4;
}
.elementor-12 .elementor-element.elementor-element-10cb21c {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-12
  .elementor-element.elementor-element-10cb21c
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-12
  .elementor-element.elementor-element-4ad6d04
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-12 .elementor-element.elementor-element-353a7d7 {
  text-align: center;
}
.elementor-12
  .elementor-element.elementor-element-353a7d7
  > .elementor-widget-container {
  margin: 0 0 -24px;
}
.elementor-12 .elementor-element.elementor-element-09bcad9 {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-12
  .elementor-element.elementor-element-09bcad9
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
}
.elementor-12 .elementor-element.elementor-element-09bcad9 .elementor-divider {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-12
  .elementor-element.elementor-element-6c9b42c:not(.elementor-motion-effects-element-type-background),
.elementor-12
  .elementor-element.elementor-element-6c9b42c
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f4f4f4;
}
.elementor-12 .elementor-element.elementor-element-6c9b42c {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-12
  .elementor-element.elementor-element-6c9b42c
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-12
  .elementor-element.elementor-element-7c40b3e
  .eael-grid-post-holder {
  background-color: #141415;
}
.elementor-12 .elementor-element.elementor-element-7c40b3e .eael-entry-title a {
  color: #fff;
}
.elementor-12
  .elementor-element.elementor-element-7c40b3e
  .eael-entry-title:hover,
.elementor-12
  .elementor-element.elementor-element-7c40b3e
  .eael-entry-title
  a:hover {
  color: #fed52c;
}
.elementor-12
  .elementor-element.elementor-element-7c40b3e
  .eael-grid-post-excerpt
  p {
  color: #fff;
}
.elementor-12
  .elementor-element.elementor-element-7c40b3e
  .eael-grid-post
  .eael-entry-overlay {
  background-color: rgba(0, 0, 0, 0.75);
}
.elementor-12
  .elementor-element.elementor-element-7c40b3e
  .eael-grid-post
  .eael-entry-overlay
  > i {
  color: #fff;
  font-size: 33px;
}
.elementor-12
  .elementor-element.elementor-element-7c40b3e
  .eael-grid-post
  .eael-entry-overlay
  > img {
  height: 33px;
  width: 33px;
}
.elementor-12
  .elementor-element.elementor-element-7c40b3e
  .eael-post-elements-readmore-btn {
  color: #61ce70;
}
.elementor-12
  .elementor-element.elementor-element-7c40b3e
  > .elementor-widget-container {
  padding: 0 0 50px;
}
.elementor-12 .elementor-element.elementor-element-1870dc5 .elementor-button {
  font-weight: 600;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  fill: #041793;
  color: #041793;
}
.elementor-12
  .elementor-element.elementor-element-1870dc5
  .elementor-button:hover,
.elementor-12
  .elementor-element.elementor-element-1870dc5
  .elementor-button:focus {
  color: #fff;
}
.elementor-12
  .elementor-element.elementor-element-1870dc5
  .elementor-button:hover
  svg,
.elementor-12
  .elementor-element.elementor-element-1870dc5
  .elementor-button:focus
  svg {
  fill: #fff;
}
.elementor-12
  .elementor-element.elementor-element-00ef1ed
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-12
  .elementor-element.elementor-element-c16afb7:not(.elementor-motion-effects-element-type-background),
.elementor-12
  .elementor-element.elementor-element-c16afb7
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../assets/images/template-for-banner-scaled-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.elementor-12 .elementor-element.elementor-element-c16afb7 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-12
  .elementor-element.elementor-element-c16afb7
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-12
  .elementor-element.elementor-element-0faf8e1
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-12 .elementor-element.elementor-element-8560993 {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-12
  .elementor-element.elementor-element-8560993
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
  margin-left: 0;
}
.elementor-12 .elementor-element.elementor-element-8560993 .elementor-divider {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-12
  .elementor-element.elementor-element-1dbf38a
  .elementor-heading-title {
  color: #fff;
}
.elementor-12
  .elementor-element.elementor-element-1dbf38a
  > .elementor-widget-container {
  margin: 0 0 18px;
}
.elementor-12
  .elementor-element.elementor-element-a9fdf95
  > .elementor-widget-container {
  margin: 0 0 48px;
}
.elementor-12 .elementor-element.elementor-element-485a925 .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  fill: #000;
  color: #000;
  background-color: #eab22e;
  border-radius: 50px;
  padding: 25px 60px;
}
.elementor-12
  .elementor-element.elementor-element-3844b9f
  .elementor-spacer-inner {
  height: 100px;
}
body.elementor-page-12:not(.elementor-motion-effects-element-type-background),
body.elementor-page-12
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #fff;
}
@media (max-width: 1024px) {
  .elementor-12
    .elementor-element.elementor-element-990ae94
    .elementor-spacer-inner {
    height: 100px;
  }
  .elementor-12
    .elementor-element.elementor-element-0bdf123
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-12
    .elementor-element.elementor-element-4e0afc4
    > .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .elementor-12
    .elementor-element.elementor-element-749c1fd
    .elementor-heading-title {
    font-size: 22px;
  }
  .elementor-12
    .elementor-element.elementor-element-749c1fd
    > .elementor-widget-container {
    padding: 0;
  }
  .elementor-12 .elementor-element.elementor-element-33b6602 .elementor-button {
    font-size: 13px;
    padding: 17px;
  }
  .elementor-12
    .elementor-element.elementor-element-33b6602
    > .elementor-widget-container {
    margin: 7px 0 0;
  }
  .elementor-12
    .elementor-element.elementor-element-ce29301
    .elementor-spacer-inner {
    height: 48px;
  }
  .elementor-12
    .elementor-element.elementor-element-ee42ebe
    .elementor-spacer-inner {
    height: 75px;
  }
  .elementor-12
    .elementor-element.elementor-element-96c594b
    .elementor-heading-title {
    font-size: 34px;
  }
  .elementor-12
    .elementor-element.elementor-element-93cd944
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
  .elementor-12
    .elementor-element.elementor-element-e39c955
    > .elementor-element-populated {
    margin: 0 25px 0 0;
  }
  .elementor-12
    .elementor-element.elementor-element-3f2e41b
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-12
    .elementor-element.elementor-element-5679d39
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-12
    .elementor-element.elementor-element-2775c09
    > .elementor-element-populated {
    margin: 0 0 0 10px;
    padding: 0;
  }
  .elementor-12
    .elementor-element.elementor-element-4db683b
    .elementor-spacer-inner {
    height: 75px;
  }
  .elementor-12
    .elementor-element.elementor-element-794d25c
    .elementor-heading-title {
    font-size: 34px;
  }
  .elementor-12
    .elementor-element.elementor-element-04147ef
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
  .elementor-12
    .elementor-element.elementor-element-94cf576
    .elementor-heading-title {
    font-size: 34px;
  }
  .elementor-12
    .elementor-element.elementor-element-8fa1666
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
  .elementor-12
    .elementor-element.elementor-element-47d220a
    > .elementor-element-populated {
    margin: 5px;
  }
  .elementor-12
    .elementor-element.elementor-element-d24e70e
    > .elementor-element-populated {
    margin: 5px;
  }
  .elementor-12
    .elementor-element.elementor-element-fca0b31
    > .elementor-element-populated {
    margin: 5px;
  }
  .elementor-12
    .elementor-element.elementor-element-afd5a20
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-12
    .elementor-element.elementor-element-288c6a4
    .elementor-heading-title {
    font-size: 34px;
  }
  .elementor-12
    .elementor-element.elementor-element-d1957ce
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
  .elementor-12
    .elementor-element.elementor-element-353a7d7
    .elementor-heading-title {
    font-size: 34px;
  }
  .elementor-12
    .elementor-element.elementor-element-09bcad9
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
  .elementor-12
    .elementor-element.elementor-element-8560993
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
}
@media (max-width: 767px) {
  .elementor-12
    .elementor-element.elementor-element-990ae94
    .elementor-spacer-inner {
    height: 100px;
  }
  .elementor-12 .elementor-element.elementor-element-0bdf123 {
    text-align: left;
  }
  .elementor-12
    .elementor-element.elementor-element-0bdf123
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-12
    .elementor-element.elementor-element-4e0afc4
    .elementor-divider {
    text-align: left;
  }
  .elementor-12
    .elementor-element.elementor-element-4e0afc4
    .elementor-divider-separator {
    margin: 0 auto;
    margin-left: 0;
  }
  .elementor-12
    .elementor-element.elementor-element-4e0afc4
    > .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .elementor-12 .elementor-element.elementor-element-749c1fd {
    text-align: left;
  }
  .elementor-12
    .elementor-element.elementor-element-749c1fd
    .elementor-heading-title {
    font-size: 19px;
  }
  .elementor-12
    .elementor-element.elementor-element-749c1fd
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 7px;
  }
  .elementor-12 .elementor-element.elementor-element-33b6602 .elementor-button {
    font-size: 11px;
    padding: 11px 12px 9px;
  }
  .elementor-12
    .elementor-element.elementor-element-ce29301
    .elementor-spacer-inner {
    height: 30px;
  }
  .elementor-12
    .elementor-element.elementor-element-ee42ebe
    .elementor-spacer-inner {
    height: 75px;
  }
  .elementor-12
    .elementor-element.elementor-element-93cd944
    > .elementor-widget-container {
    padding: 0;
  }
  .elementor-12
    .elementor-element.elementor-element-e39c955
    > .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .elementor-12
    .elementor-element.elementor-element-809c6fd
    .elementor-text-editor {
    text-align: center;
  }
  .elementor-12
    .elementor-element.elementor-element-4aa8fbc
    .elementor-text-editor {
    text-align: center;
  }
  .elementor-12 .elementor-element.elementor-element-6ad0610 .elementor-button {
    font-size: 14px;
    padding: 15px 20px 16px;
  }
  .elementor-12
    .elementor-element.elementor-element-2775c09
    > .elementor-element-populated {
    margin: 0;
    padding: 0 0 150px;
  }
  .elementor-12
    .elementor-element.elementor-element-04147ef
    > .elementor-widget-container {
    padding: 0;
  }
  .elementor-12 .elementor-element.elementor-element-a82b062 {
    font-size: 18px;
    line-height: 1.1em;
  }
  .elementor-12
    .elementor-element.elementor-element-8fa1666
    > .elementor-widget-container {
    padding: 0;
  }
  .elementor-12
    .elementor-element.elementor-element-47d220a
    > .elementor-element-populated {
    margin: 0 0 18px;
  }
  .elementor-12
    .elementor-element.elementor-element-d24e70e
    > .elementor-element-populated {
    margin: 0 0 18px;
  }
  .elementor-12
    .elementor-element.elementor-element-fca0b31
    > .elementor-element-populated {
    margin: 0;
  }
  .elementor-12
    .elementor-element.elementor-element-afd5a20
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-12
    .elementor-element.elementor-element-d1957ce
    > .elementor-widget-container {
    padding: 0;
  }
  .elementor-12
    .elementor-element.elementor-element-4ad6d04
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-12
    .elementor-element.elementor-element-09bcad9
    > .elementor-widget-container {
    padding: 0;
  }
  .elementor-12
    .elementor-element.elementor-element-7c40b3e
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 30px;
  }
  .elementor-12
    .elementor-element.elementor-element-8560993
    > .elementor-widget-container {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .elementor-12 .elementor-element.elementor-element-e39c955 {
    width: 57.958%;
  }
  .elementor-12 .elementor-element.elementor-element-2775c09 {
    width: 42%;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .elementor-12 .elementor-element.elementor-element-e39c955 {
    width: 58%;
  }
  .elementor-12 .elementor-element.elementor-element-2775c09 {
    width: 42%;
  }
}
@media (min-width: 1025px) {
  .elementor-12
    .elementor-element.elementor-element-8a0de87:not(.elementor-motion-effects-element-type-background),
  .elementor-12
    .elementor-element.elementor-element-8a0de87
    > .elementor-motion-effects-container
    > .elementor-motion-effects-layer {
    background-attachment: scroll;
  }
}
