.mb-2{
  margin-bottom: .8em !important;
}
.toast {
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  position: absolute;
  z-index: 1090;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(var(--bs-toast-padding-x) * -0.5);
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}
.position-fixed{
  position: fixed;
}

.bottom-0{
  bottom: 0;
}
.end-0{
  right: 0;
} 
.p-3{
  padding: 15px;
}

.elementor-1088
  .elementor-element.elementor-element-7e31ea7:not(.elementor-motion-effects-element-type-background),
.elementor-1088
  .elementor-element.elementor-element-7e31ea7
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../assets/images/20190525_093540-scaled.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.elementor-1088
  .elementor-element.elementor-element-7e31ea7
  > .elementor-background-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0.8;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1088 .elementor-element.elementor-element-7e31ea7 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-1088
  .elementor-element.elementor-element-18aafb8
  .elementor-spacer-inner {
  height: 214px;
}
.elementor-1088 .elementor-element.elementor-element-8636227 {
  text-align: left;
}
.elementor-1088
  .elementor-element.elementor-element-8636227
  .elementor-heading-title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}
.elementor-1088
  .elementor-element.elementor-element-8636227
  > .elementor-widget-container {
  margin: 0 0 -20px;
}
.elementor-1088 .elementor-element.elementor-element-bef0ce4 {
  --divider-border-style: solid;
  --divider-border-color: #f2b22e;
  --divider-border-width: 3.8px;
}
.elementor-1088
  .elementor-element.elementor-element-bef0ce4
  .elementor-divider-separator {
  width: 100px;
  margin: 0 auto;
  margin-left: 0;
}
.elementor-1088
  .elementor-element.elementor-element-bef0ce4
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.elementor-1088
  .elementor-element.elementor-element-bef0ce4
  > .elementor-widget-container {
  padding: 0;
}
.elementor-1088 .elementor-element.elementor-element-89c40b6 {
  text-align: left;
}
.elementor-1088
  .elementor-element.elementor-element-89c40b6
  .elementor-heading-title {
  color: #fff;
  font-size: 45px;
}
.elementor-1088
  .elementor-element.elementor-element-89c40b6
  > .elementor-widget-container {
  padding: 0 0 50px;
}
.elementor-1088
  .elementor-element.elementor-element-05b521f
  .elementor-spacer-inner {
  height: 150px;
}
.elementor-1088
  .elementor-element.elementor-element-2584a79
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-1088 .elementor-element.elementor-element-9e05a68 {
  text-align: center;
}
.elementor-1088
  .elementor-element.elementor-element-9e05a68
  .elementor-heading-title {
  font-size: 36px;
}
.elementor-1088
  .elementor-element.elementor-element-9e05a68
  > .elementor-widget-container {
  margin: 0 0 -22px;
}
.elementor-1088 .elementor-element.elementor-element-626b33b {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-1088
  .elementor-element.elementor-element-626b33b
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
}
.elementor-1088
  .elementor-element.elementor-element-626b33b
  .elementor-divider {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-1088
  .elementor-element.elementor-element-626b33b
  > .elementor-widget-container {
  margin: 0 0 -21px;
}
.elementor-1088 .elementor-element.elementor-element-ad658ca {
  color: #6e6e6e;
  font-size: 19px;
  line-height: 1.3em;
}
.elementor-1088
  .elementor-element.elementor-element-ad658ca
  > .elementor-widget-container {
  padding: 20px 20px 0;
}
.elementor-1088
  .elementor-element.elementor-element-25648f6
  > .elementor-widget-container {
  padding: 5px;
}
.elementor-1088
  .elementor-element.elementor-element-168d6b7
  .elementor-spacer-inner {
  height: 38px;
}
.elementor-1088
  .elementor-element.elementor-element-6a4777d:not(.elementor-motion-effects-element-type-background),
.elementor-1088
  .elementor-element.elementor-element-6a4777d
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../assets/images/template-for-banner-scaled-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.elementor-1088 .elementor-element.elementor-element-6a4777d {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-1088
  .elementor-element.elementor-element-6a4777d
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1088
  .elementor-element.elementor-element-e921abe
  .elementor-spacer-inner {
  height: 100px;
}
.elementor-1088 .elementor-element.elementor-element-3db52f4 {
  --divider-border-style: solid;
  --divider-border-color: #fed513;
  --divider-border-width: 5px;
}
.elementor-1088
  .elementor-element.elementor-element-3db52f4
  .elementor-divider-separator {
  width: 121px;
  margin: 0 auto;
  margin-left: 0;
}
.elementor-1088
  .elementor-element.elementor-element-3db52f4
  .elementor-divider {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-1088
  .elementor-element.elementor-element-891f68b
  .elementor-heading-title {
  color: #fff;
}
.elementor-1088
  .elementor-element.elementor-element-891f68b
  > .elementor-widget-container {
  margin: 0 0 18px;
}
.elementor-1088
  .elementor-element.elementor-element-8e1d1c7
  > .elementor-widget-container {
  margin: 0 0 48px;
}
.elementor-1088 .elementor-element.elementor-element-3b11c87 .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  fill: #000;
  color: #000;
  background-color: #eab22e;
  border-radius: 50px;
  padding: 25px 60px;
}
.elementor-1088
  .elementor-element.elementor-element-be3fe81
  .elementor-spacer-inner {
  height: 100px;
}
@media (min-width: 1025px) {
  .elementor-1088
    .elementor-element.elementor-element-7e31ea7:not(.elementor-motion-effects-element-type-background),
  .elementor-1088
    .elementor-element.elementor-element-7e31ea7
    > .elementor-motion-effects-container
    > .elementor-motion-effects-layer {
    background-attachment: scroll;
  }
}
@media (max-width: 1024px) {
  .elementor-1088
    .elementor-element.elementor-element-18aafb8
    .elementor-spacer-inner {
    height: 100px;
  }
  .elementor-1088
    .elementor-element.elementor-element-89c40b6
    .elementor-heading-title {
    font-size: 22px;
  }
  .elementor-1088
    .elementor-element.elementor-element-89c40b6
    > .elementor-widget-container {
    padding: 0;
  }
  .elementor-1088
    .elementor-element.elementor-element-05b521f
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-1088
    .elementor-element.elementor-element-2584a79
    .elementor-spacer-inner {
    height: 36px;
  }
  .elementor-1088
    .elementor-element.elementor-element-626b33b
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
  .elementor-1088
    .elementor-element.elementor-element-3db52f4
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 25px;
  }
}
@media (max-width: 767px) {
  .elementor-1088
    .elementor-element.elementor-element-18aafb8
    .elementor-spacer-inner {
    height: 75px;
  }
  .elementor-1088 .elementor-element.elementor-element-8636227 {
    text-align: left;
  }
  .elementor-1088
    .elementor-element.elementor-element-8636227
    .elementor-heading-title {
    font-size: 19px;
  }
  .elementor-1088
    .elementor-element.elementor-element-bef0ce4
    .elementor-divider {
    text-align: left;
  }
  .elementor-1088
    .elementor-element.elementor-element-bef0ce4
    .elementor-divider-separator {
    margin: 0 auto;
    margin-left: 0;
  }
  .elementor-1088
    .elementor-element.elementor-element-bef0ce4
    > .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .elementor-1088 .elementor-element.elementor-element-89c40b6 {
    text-align: left;
  }
  .elementor-1088
    .elementor-element.elementor-element-89c40b6
    .elementor-heading-title {
    font-size: 21px;
  }
  .elementor-1088
    .elementor-element.elementor-element-89c40b6
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 30px;
  }
  .elementor-1088
    .elementor-element.elementor-element-05b521f
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-1088
    .elementor-element.elementor-element-2584a79
    .elementor-spacer-inner {
    height: 13px;
  }
  .elementor-1088
    .elementor-element.elementor-element-9e05a68
    .elementor-heading-title {
    font-size: 29px;
    line-height: 0.9em;
  }
  .elementor-1088
    .elementor-element.elementor-element-626b33b
    > .elementor-widget-container {
    padding: 0;
  }
  .elementor-1088
    .elementor-element.elementor-element-3db52f4
    > .elementor-widget-container {
    padding: 0;
  }
}


.support-us{
    font-size: 51px; font-style: normal; font-weight: 700; color: rgb(255, 255, 255); font-family: 'Trebuchet MS', sans-serif; text-transform: uppercase;
}