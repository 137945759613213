.site-card-content #primary {
  margin: 4em 0 !important;
}

@media (min-width: 769px) {
  .site-card-content .ast-container {
    max-width: 1240px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.site-card-content .woocommerce-info::before {
  font-family: WooCommerce;
  content: none;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
}

.cart_totals p small,
.woocommerce-cart .cart-collaterals .cart_totals p small,
.woocommerce-checkout .cart-collaterals .cart_totals p small {
  color: #777;
  font-size: 0.83em;
}
#add_payment_method .cart-collaterals .cart_totals table,
.woocommerce-cart .cart-collaterals .cart_totals table,
.woocommerce-checkout .cart-collaterals .cart_totals table {
  border-collapse: separate;
  margin: 0 0 6px;
  padding: 0;
}
#add_payment_method .cart-collaterals .cart_totals table tr:first-child td,
#add_payment_method .cart-collaterals .cart_totals table tr:first-child th,
.woocommerce-cart .cart-collaterals .cart_totals table tr:first-child td,
.woocommerce-cart .cart-collaterals .cart_totals table tr:first-child th,
.woocommerce-checkout .cart-collaterals .cart_totals table tr:first-child td,
.woocommerce-checkout .cart-collaterals .cart_totals table tr:first-child th {
  border-top: 0;
}
#add_payment_method .cart-collaterals .cart_totals table th,
.woocommerce-cart .cart-collaterals .cart_totals table th,
.woocommerce-checkout .cart-collaterals .cart_totals table th {
  width: 40%;
}
#add_payment_method .cart-collaterals .cart_totals table td,
#add_payment_method .cart-collaterals .cart_totals table th,
.woocommerce-cart .cart-collaterals .cart_totals table td,
.woocommerce-cart .cart-collaterals .cart_totals table th,
.woocommerce-checkout .cart-collaterals .cart_totals table td,
.woocommerce-checkout .cart-collaterals .cart_totals table th {
  vertical-align: top;
  border-left: 0;
  border-right: 0;
  line-height: 1.5em;
}
#add_payment_method .cart-collaterals .cart_totals table small,
.woocommerce-cart .cart-collaterals .cart_totals table small,
.woocommerce-checkout .cart-collaterals .cart_totals table small {
  color: #777;
}
#add_payment_method .cart-collaterals .cart_totals table select,
.woocommerce-cart .cart-collaterals .cart_totals table select,
.woocommerce-checkout .cart-collaterals .cart_totals table select {
  width: 100%;
}
#add_payment_method .cart-collaterals .cart_totals .discount td,
.woocommerce-cart .cart-collaterals .cart_totals .discount td,
.woocommerce-checkout .cart-collaterals .cart_totals .discount td {
  color: #77a464;
}
#add_payment_method .cart-collaterals .cart_totals tr td,
#add_payment_method .cart-collaterals .cart_totals tr th,
.woocommerce-cart .cart-collaterals .cart_totals tr td,
.woocommerce-cart .cart-collaterals .cart_totals tr th,
.woocommerce-checkout .cart-collaterals .cart_totals tr td,
.woocommerce-checkout .cart-collaterals .cart_totals tr th {
  border-top: 1px solid #ebe9eb;
}
#add_payment_method .cart-collaterals .cross-sells ul.products li.product,
.woocommerce-cart .cart-collaterals .cross-sells ul.products li.product,
.woocommerce-checkout .cart-collaterals .cross-sells ul.products li.product {
  margin-top: 0;
}


