.elementor-16
  .elementor-element.elementor-element-ef418b4
  > .elementor-container
  > .elementor-row
  > .elementor-column
  > .elementor-column-wrap
  > .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.elementor-16
  .elementor-element.elementor-element-ef418b4:not(.elementor-motion-effects-element-type-background),
.elementor-16
  .elementor-element.elementor-element-ef418b4
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../assets/images/RTVFOUNDATION_BANNER-1024x577-1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.elementor-16
  .elementor-element.elementor-element-ef418b4
  > .elementor-background-overlay {
  background-color: rgba(65, 65, 65, 0.73);
  opacity: 0.8;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-16 .elementor-element.elementor-element-ef418b4 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-16
  .elementor-element.elementor-element-202700df
  .elementor-spacer-inner {
  height: 177px;
}
.elementor-16 .elementor-element.elementor-element-a19080f {
  text-align: left;
}
.elementor-16
  .elementor-element.elementor-element-a19080f
  .elementor-heading-title {
  color: #fff;
  font-size: 35px;
}
.elementor-16
  .elementor-element.elementor-element-a19080f
  > .elementor-widget-container {
  margin: 0 0 -21px;
  padding: 0;
}
.elementor-16 .elementor-element.elementor-element-33e781a0 {
  --divider-border-style: solid;
  --divider-border-color: #f2b22e;
  --divider-border-width: 4px;
}
.elementor-16
  .elementor-element.elementor-element-33e781a0
  .elementor-divider-separator {
  width: 123px;
  margin: 0 auto;
  margin-left: 0;
}
.elementor-16 .elementor-element.elementor-element-33e781a0 .elementor-divider {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.elementor-16 .elementor-element.elementor-element-0944c31 {
  text-align: left;
}
.elementor-16
  .elementor-element.elementor-element-0944c31
  .elementor-heading-title {
  color: #fff;
  font-size: 29px;
}
.elementor-16
  .elementor-element.elementor-element-0944c31
  > .elementor-widget-container {
  margin: 0 0 -18px;
}
.elementor-16
  .elementor-element.elementor-element-18e3e09f
  .elementor-spacer-inner {
  height: 148px;
}
.elementor-16
  .elementor-element.elementor-element-9920f85
  > .elementor-container
  > .elementor-row
  > .elementor-column
  > .elementor-column-wrap
  > .elementor-widget-wrap {
  align-content: flex-start;
  align-items: flex-start;
}
.elementor-16
  .elementor-element.elementor-element-e04da06
  .elementor-spacer-inner {
  height: 27px;
}
.elementor-16 .elementor-element.elementor-element-6c85b8f {
  text-align: left;
}
.elementor-16
  .elementor-element.elementor-element-6c85b8f
  .elementor-heading-title {
  font-size: 30px;
}
.elementor-16
  .elementor-element.elementor-element-c932149
  .elementor-text-editor {
  text-align: left;
}
.elementor-16
  .elementor-element.elementor-element-0c7b3e4
  .elementor-spacer-inner {
  height: 10px;
}
.elementor-16 .elementor-element.elementor-element-ab9b970 {
  text-align: left;
}
.elementor-16
  .elementor-element.elementor-element-ab9b970
  .elementor-heading-title {
  font-size: 30px;
}
.elementor-16
  .elementor-element.elementor-element-787d818
  .elementor-text-editor {
  text-align: left;
}
.elementor-16 .elementor-element.elementor-element-8455c74 {
  text-align: left;
}
.elementor-16
  .elementor-element.elementor-element-8455c74
  .elementor-heading-title {
  font-size: 26px;
}
.elementor-16
  .elementor-element.elementor-element-8455c74
  > .elementor-widget-container {
  margin: 26px 0 0;
}
.elementor-16
  .elementor-element.elementor-element-b7519d0
  .elementor-icon-list-icon
  i {
  font-size: 14px;
}
.elementor-16
  .elementor-element.elementor-element-b7519d0
  .elementor-icon-list-icon
  svg {
  width: 14px;
}
.elementor-16
  .elementor-element.elementor-element-e136416
  .elementor-spacer-inner {
  height: 10px;
}
.elementor-16 .elementor-element.elementor-element-4e92a85 {
  text-align: left;
}
.elementor-16
  .elementor-element.elementor-element-4e92a85
  .elementor-heading-title {
  font-size: 26px;
}
.elementor-16 .elementor-element.elementor-element-5997d79 {
  text-align: left;
}
.elementor-16
  .elementor-element.elementor-element-5997d79
  .elementor-social-icon {
  background-color: #7a7a7a;
  font-size: 21px;
  padding: 0.6em;
}
.elementor-16
  .elementor-element.elementor-element-5997d79
  .elementor-social-icon
  i {
  color: #fff;
}
.elementor-16
  .elementor-element.elementor-element-5997d79
  .elementor-social-icon
  svg {
  fill: #fff;
}
.elementor-16
  .elementor-element.elementor-element-5997d79
  .elementor-social-icon:not(:last-child) {
  margin-right: 7px;
}
.elementor-16
  .elementor-element.elementor-element-5997d79
  .elementor-social-icon:hover {
  background-color: #ee0979;
}
.elementor-16
  .elementor-element.elementor-element-f915551
  .elementor-spacer-inner {
  height: 27px;
}
.elementor-16 .elementor-element.elementor-element-ec9f039 {
  text-align: left;
}
.elementor-16
  .elementor-element.elementor-element-ec9f039
  .elementor-heading-title {
  font-size: 30px;
}
.elementor-16
  .elementor-element.elementor-element-772c9f9
  .elementor-spacer-inner {
  height: 75px;
}
body.elementor-page-16:not(.elementor-motion-effects-element-type-background),
body.elementor-page-16
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #fff;
}
@media (min-width: 1025px) {
  .elementor-16
    .elementor-element.elementor-element-ef418b4:not(.elementor-motion-effects-element-type-background),
  .elementor-16
    .elementor-element.elementor-element-ef418b4
    > .elementor-motion-effects-container
    > .elementor-motion-effects-layer {
    background-attachment: scroll;
  }
}
@media (max-width: 1024px) {
  .elementor-16
    .elementor-element.elementor-element-202700df
    .elementor-spacer-inner {
    height: 75px;
  }
  .elementor-16
    .elementor-element.elementor-element-a19080f
    .elementor-heading-title {
    font-size: 32px;
  }
  .elementor-16
    .elementor-element.elementor-element-33e781a0
    > .elementor-widget-container {
    margin: 0;
    padding: 0 0 5px;
  }
  .elementor-16
    .elementor-element.elementor-element-0944c31
    .elementor-heading-title {
    font-size: 24px;
  }
  .elementor-16
    .elementor-element.elementor-element-18e3e09f
    .elementor-spacer-inner {
    height: 75px;
  }
  .elementor-16
    .elementor-element.elementor-element-e04da06
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-16
    .elementor-element.elementor-element-f915551
    .elementor-spacer-inner {
    height: 10px;
  }
  .elementor-16
    .elementor-element.elementor-element-772c9f9
    .elementor-spacer-inner {
    height: 50px;
  }
}
@media (max-width: 767px) {
  .elementor-16
    .elementor-element.elementor-element-202700df
    .elementor-spacer-inner {
    height: 110px;
  }
  .elementor-16 .elementor-element.elementor-element-a19080f {
    text-align: left;
  }
  .elementor-16
    .elementor-element.elementor-element-a19080f
    .elementor-heading-title {
    font-size: 27px;
  }
  .elementor-16
    .elementor-element.elementor-element-a19080f
    > .elementor-widget-container {
    margin: 0 0 -25px;
    padding: 0;
  }
  .elementor-16
    .elementor-element.elementor-element-33e781a0
    .elementor-divider-separator {
    width: 32%;
  }
  .elementor-16
    .elementor-element.elementor-element-33e781a0
    > .elementor-widget-container {
    padding: 0;
  }
  .elementor-16
    .elementor-element.elementor-element-0944c31
    .elementor-heading-title {
    font-size: 19px;
  }
  .elementor-16
    .elementor-element.elementor-element-18e3e09f
    .elementor-spacer-inner {
    height: 29px;
  }
  .elementor-16
    .elementor-element.elementor-element-e04da06
    .elementor-spacer-inner {
    height: 25px;
  }
  .elementor-16
    .elementor-element.elementor-element-6c85b8f
    .elementor-heading-title {
    font-size: 28px;
  }
  .elementor-16
    .elementor-element.elementor-element-0c7b3e4
    .elementor-spacer-inner {
    height: 1px;
  }
}
