.elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1200px;

}
.elementor-widget:not(:last-child) {
  margin-bottom: 10px;
}

.error-404{
    padding-top: 150px;
    padding-bottom: 150px;
}